import { Component, OnInit } from '@angular/core';
import { bmxurl } from 'src/globals';
import { cPirequest } from 'src/types/pirequest';
import { piresponse_returndata } from 'src/types/piresponse_returndata';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { bookingsummary, cBookingsummary } from 'src/types/bookingsummary';

declare var Stripe: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  BookingConfirmationSummary: string;
  BookingConfirmationText: string;
  BookingSummary: bookingsummary;
  version: string = environment.version;
  VenueCSS: any;
  PaymentInProgress: boolean;
  paymentError: string;
  RyftSubAccountID: string = "";
  PIReponse: any;
  PIRequest: cPirequest;
  VenueID: string;
  httpResponse: any;
  PauseTimerEffect: boolean;
  paymmentjwt: string = "";
  BookingId: string = "";
  paymentAmount: number = 0;
  bookingIdHR: string = "";

  MinimalCustomerDetails: boolean = false;
  publicImageURL: string = "";
  imageHeight: number = 150;
  imageWidth: number = 150;
  defaultColour: string = "";
  EmailSMSConfirmation: string = "";
  GoogleTagManagerID: string = "";
  returnPage: string = "";
  SetupComplete: boolean = false;

  BookingComplete: boolean = false;
  showButton: boolean = true;
  booking: any;

  constructor(private http: HttpClient, private route: ActivatedRoute,) { 
    this.route.queryParamMap.subscribe(params => {
      this.paymmentjwt = params.get('token');
    });
  }

  ngOnInit(): void {
    this.http.get(bmxurl + "makepayment/" + this.paymmentjwt).subscribe(response => {
      this.httpResponse = (response as any);
      if(this.httpResponse.succeeded)
      {
        this.VenueID = this.httpResponse.data.venueid;
        this.BookingId = this.httpResponse.data.bookingid;
        this.paymentAmount = this.httpResponse.data.amount;
        this.bookingIdHR = this.httpResponse.data.bookingidhr;

        this.booking = this.httpResponse.data.booking;

        if(this.paymmentjwt == "")
        {
          this.paymentError = "An error has occured, please contact the venue, a payment cannot be made at this time.";
        }
        else{
          this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
            this.httpResponse =  (response as venuesettings_returndata)
            
            if(this.httpResponse.succeeded)
            {
              this.httpResponse.data.venueSettings.forEach(element => {
                localStorage.setItem(element.name, element.value);
      
                if(element.name == "MinimalDetails")
                  this.MinimalCustomerDetails = element.value == "true" ? true : false;
                if(element.name == "PublicImgURL")
                  this.publicImageURL = element.value;
                if(element.name == "PublicImageheight")
                  this.imageHeight = element.value;
                if(element.name == "PublicImageWidth")
                  this.imageWidth = element.value;
                if(element.name == "DefaultColour")
                  this.defaultColour = element.value;
                if(element.name == "EMailSMSConfirmation")
                  this.EmailSMSConfirmation = element.value;
                if(element.name == "GoogleTagManagerID")
                  this.GoogleTagManagerID = element.value;
                if(element.name == "RyftSubAccountID")
                  this.RyftSubAccountID = element.value;
                if(element.name == "returnPage")
                  this.returnPage = element.value;
              });
              this.SetupPayment(this.httpResponse);
            }
            else{

            }
            }, error => {

            });
        }
      }
  });
}

  private SetupPayment(inBookingInfo: any) {

    this.paymentError = "";

    let PKKey: string = environment.PaymentAccountNumber;
    let StripeAccount = this.RyftSubAccountID;

    const stripe = Stripe(PKKey,
      {
        stripeAccount: StripeAccount
      });

    //Go get the PI
    this.PIRequest = {
      amount: Math.ceil(this.paymentAmount * 100),
      currency: "GBP",
      description: "Booking - " + this.bookingIdHR,
      bookingid: this.BookingId,
      venueid: this.VenueID,
      StripeAccount: StripeAccount,
      customer: null
    };

    this.http.post(bmxurl + "StripePI", this.PIRequest).subscribe(response => {
      this.PIReponse = (response as piresponse_returndata);

      if(this.PIReponse.succeeded)
      {
        this.SetupComplete = true;

        const options = {
          layout: {
            type: 'accordion',
            defaultCollapsed: false,
          }
        };
  
        const appearance = {
          theme: 'stripe',
          labels: 'floating'
          
        };
  
        const elements = stripe.elements({clientSecret: this.PIReponse.data.clientSecret, appearance });
        const card = elements.create('payment', options);
        card.mount('#payment-element');

        card.addEventListener('change', event => {
          const displayError = document.getElementById('payment-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });
  
        const paymentForm = document.getElementById('payment-form');
        paymentForm.addEventListener('submit', async (event) => {
          //paymentForm.style.display = "none";
          //Stop Timer
          this.PauseTimerEffect = true;
          this.PaymentInProgress = true;
          event.preventDefault();
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: "if_required"
          });
          const { paymentIntent } = await stripe.retrievePaymentIntent(this.PIReponse.data.clientSecret);
          if (paymentIntent.status == "succeeded")
          {
            this.paymentError = "";
            paymentForm.style.display = "none";
            this.BookingComplete = true;
            this.DoSummary(this.booking);
            this.PaymentInProgress = false;
          }
          else
          {
            this.paymentError = "Your payment has failed, please try again.";
            paymentForm.style.removeProperty("display");

            this.PauseTimerEffect = false;
            this.PaymentInProgress = false;
          }
        });

      }
      else
      {
        const paymentForm = document.getElementById('payment-form');
        paymentForm.style.display = "none";
        this.PaymentInProgress = false;

        this.paymentError = "An error has occured, please contact the venue, a payment cannot be made at this time.";
      }

    }, error => {
        const paymentForm = document.getElementById('payment-form');
        paymentForm.style.display = "none";
        this.PaymentInProgress = false;

        this.paymentError = "An error has occured, please contact the venue, a payment cannot be made at this time.";
    });
  }
  DoSummary(BookingSummary)
  {
    let admissionsText: string = "";
    let extrasText: string = "";

    BookingSummary.resources[0].admissions.forEach(adm => {
      admissionsText += adm.noofpeople + " x " +  adm.name + ", ";
    });

    if(admissionsText.length > 2)
    admissionsText = admissionsText.substring(0, admissionsText.length - 2);

    if(BookingSummary.extras != null)
    {
      if(BookingSummary.extras.length > 0)
      {
        BookingSummary.extras.forEach(element => {
          if(element.qty > 0)
            extrasText += element.name + " x " + element.qty + ", ";
        });
      }
    }

    if(extrasText.length > 2)
    {
      extrasText = extrasText.substring(0, extrasText.length - 2);
    }

    let newBookingSummary:any = {
      Disabled: BookingSummary.disabled,
      bookingId: BookingSummary.bookingId,
      bookingIdHR: BookingSummary.bookingIdHR,
      admissions: admissionsText,
      startTime: BookingSummary.startTime,
      endTime: BookingSummary.endTime,
      name: BookingSummary.name,
      arrival: BookingSummary.arrival,
      guests: BookingSummary.guests,
      total: BookingSummary.total,
      outstanding: BookingSummary.outstanding - Number(this.paymentAmount),
      paid: Number(this.paymentAmount),
      totaldiscounts: Number(BookingSummary.totaldiscounts),
      bookingfee: Number(BookingSummary.bookingfee),
      customerName: BookingSummary.name,
      packageName:  BookingSummary.packageName,
      publicBooking: true,
      extras: extrasText,
      itinerary: BookingSummary.itinerary,
      itineraryEX: BookingSummary.itineraryEX,
      notes: BookingSummary.notes
    }

    this.BookingSummary = newBookingSummary;
    this.BookingComplete = true;
  }

  addMs = (date: Date, ms: number): Date => {
    const result = new Date(date);
    result.setMilliseconds(result.getMilliseconds() + ms);
    return result;
  };
  
  doButton(){
    this.showButton = true;
  }

}
