import { Component, OnInit } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

import { bmxurl } from '../../globals';
import { terms_returndata } from '../../types/terms_returndata';
import { cTerms } from '../../types/terms';
import { host_returndata } from '../../types/host_returndata';
import { venuesettings_returndata } from '../../types/venuesettings_returndata';
import { MessageService } from 'primeng/api';
import { LoadingInterceptor } from '../_interceptors/loading.interceptor';


@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [
    HttpClientModule
  ],
  providers: [ 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    HttpClientModule,
    MessageService
 ],
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit  {

  publicImageURL: string = "";
  imageWidth: number = 150;
  imageHeight: number = 150;
  TermsAndConditions: string = "";
  httpResponse: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  venueName: string = "";
  version: string = environment.version;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private titleService: Title,
    private messageService: MessageService,
    ) { 
    this.route.params.subscribe(params => {
      let param = params['venue'];

      this.http.get(bmxurl + "/host/" + param).subscribe(response => {
        
        this.httpResponse =  (response as host_returndata);

        if((response as host_returndata).succeeded)
        {
          if(this.httpResponse.data != null)
          {
            this.venueName = this.httpResponse.data.venuename;
            this.VenueID = this.httpResponse.data.venueid;
            localStorage.setItem("SmartVenueID", this.VenueID);
            this.titleService.setTitle("Terms - " + this.venueName);
          }
          else
          {
            this.VenueID = "";
          }


          this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
          this.httpResponse =  (response as venuesettings_returndata)
          
          if(this.httpResponse.succeeded)
          {
            //Set Venue Settings ID
            localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
        
            this.httpResponse.data.venueSettings.forEach((element: any) => {
              localStorage.setItem(element.name, element.value);

              if(element.name == "PublicImgURL")
                this.publicImageURL = element.value;
              if(element.name == "PublicImageheight")
                this.imageHeight = Number(element.value);
              if(element.name == "PublicImageWidth")
                this.imageWidth = Number(element.value);
            });

          }
          else{
            this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
        });

        }

      }, error => {
        console.log(error);
      })
    });

  }

  ngOnInit(): void {
    this.http.get(bmxurl + "terms/" + this.VenueID).subscribe(response => {
      this.httpResponse = (response as terms_returndata)
      if (this.httpResponse.succeeded)
      {
        this.TermsAndConditions = (this.httpResponse.data as cTerms).terms;

        this.http.get(bmxurl + "terms").subscribe(response => {
          this.httpResponse = (response as terms_returndata)
          if (this.httpResponse.succeeded)
          {
            this.TermsAndConditions += "<BR>" +  (this.httpResponse.data as cTerms).terms;
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Terms & Conditions'});
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Terms & Conditions, error:' + error.messages[0]});
        });

      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Terms & Conditions'});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Terms & Conditions, error:' + error.messages[0]});
    });

  }

}
