    <div class="padding15px">
        <div class="publicImage"><img src={{publicImageURL}} width={{imageWidth}} height={{imageHeight}}></div>
    </div>
    <div style="padding:20px;" [innerHTML]="TermsAndConditions"></div>
    <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;">
        Version: {{version}}
        <br>
        <a href="https://wannabook.online">Powered by WannaBook</a>
        <div style="height:83px;">
          &nbsp;
      </div>
    </div>
