
//#region imports
import { Component, OnInit, OnDestroy, AfterContentInit, DoCheck, Renderer2} from '@angular/core';
import { bmxurl} from 'src/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AvailableTimes, AvailabilityReq, Admission, Package_returndata, Payment, Customer } from '../../PackageClasses';
import { customer } from 'src/types/customer';
import { booking } from 'src/types/booking';
import { MessageService } from 'primeng/api';
import { cExtra } from 'src/types/extra';
import { cPackageExtra } from 'src/types/packageExtra';
//import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { cPirequest } from 'src/types/pirequest';
import { piresponse_returndata } from 'src/types/piresponse_returndata';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { ICardValidation, IPaymentSession, IRyft } from 'src/types/ryft';
import { cResource, resource } from 'src/types/resource';
import { v4 as uuidv4  } from 'uuid';
import { paymenSecret } from 'src/types/paymentSecret';
import { paymentSecret_returndata } from 'src/types/paymentSecret_returndata';
import { BusyService } from '../_services/busy.service';
import { customer_returndata } from 'src/types/customer_returndata';
import { terms_returndata } from 'src/types/terms_returndata';
import { cTerms } from 'src/types/terms';
import { admission, cAdmission } from 'src/types/admission';
import { packagegroups_returndata } from 'src/types/packagegroup_returndata';
import { booking_returndata } from 'src/types/booking_returndata';
import { host_returndata } from 'src/types/host_returndata';
import { bookingsummary, cBookingsummary } from 'src/types/bookingsummary';
import { bookingJWT } from 'src/types/bookingJWT';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { cDiscountvalidate } from 'src/types/discountvailidate';
import { map, takeWhile } from 'rxjs/operators';
import { timer } from 'rxjs';
import {Title} from "@angular/platform-browser";
import { DomSanitizer } from '@angular/platform-browser';
import { bookingquestionitem } from 'src/types/bookingquestion';
import { allergyanswer, birthdayanswers, questionanswers } from 'src/types/questionanswers';
import { cVouchervalidate } from 'src/types/vouchervalidate';
//import LogRocket from 'logrocket';
import { cEvent } from 'src/types/event';
import { cPackage } from 'src/types/package';
import { cVenueclosings } from 'src/types/venueclosings';
import { venueclosings_returndata } from 'src/types/venueclosings_returndata';
import { ViewportScroller } from '@angular/common';
import { package_returndata } from 'src/types/package_returndata';
import { link_returndata } from 'src/types/link_returndata';

//#endregion

declare var Stripe: any;

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {

  //#region variables
  BookingComplete: boolean = false;
  packages: any;
  packages1: any;
  SelectedPackage: any;
  SelectedResources: any[] = []
  SelectedDate: Date;
  startTimes: any;
  startTimesFiltered: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateCustomer: any;
  PaymentTypes: any = ["Cash", "Card"];
  selectedPayment: any;
  packageExtras: cPackageExtra[];
  selectedPackageExtras: cExtra[];

  BookingDateAndTime: string = "";
  BookingPackage: string = "";
  BookingAdmissions: string = "";
  BookingAddress: string = "";
  BookingEmail: string = "";
  BookingMobile: string = "";
  BookingValue: string = "";
  Itinerary: string = "";

  paymentValueValid: boolean = true;
  paymentTypeValid: boolean = true;
  paymentValueExceededValid: boolean = true;
  paymentDepositValid: boolean = true;
  validPaymentAmount: number = 0;

  AllValid: boolean =true;
  CustomerAllValid: boolean =true;

  PackageAdmissions: admission[];
  SelectedAdmissions: admission[];

  PackageHeader: string = "Select a Package";
  CalendarHeader: string = "Select a Date";
  AdmissionHeader: string = "Select your Admissions";
  TimeHeader: string = "Select a Time";
  CustomerHeader: string = "Enter your details";
  PaymentHeader: string = "Add a Payment";
  ExtrasHeader: string = "Select Extras";
  SelResourceHeader: string = "Please Select";

  packageSelected: Boolean;
  admissionsSelected: Boolean;
  dateSelected: Boolean;
  timeSelected: Boolean;
  customerSelected:Boolean;
  paymentSelected:Boolean;
  extraSelected: Boolean;
  partyroomsSelected: Boolean;
  bookingQuestionsSelected: Boolean;

  packageActive: Boolean = false;
  admissionsActive: Boolean = false;
  dateActive: Boolean = false;
  timeActive: Boolean = false;
  customerActive:Boolean = false;
  paymentActive:Boolean = false;
  extraActive: Boolean = false;
  partyroomsActive: Boolean = false;
  partyroomsVisible: Boolean = false;
  bookingQuestionsActive: Boolean;

  //Customer
  firstName: string = "";
  lastName: string = "";
  address1: string = "";
  address2: string = "";
  town: string = "";
  postCode: string = "";
  email: string = "";
  mobile: string = "";
  dob: Date = new Date(1900, 1, 1);

  firstNameValid: boolean = true;
  lastNameValid: boolean = true;
  Address1Valid: boolean = true;
  TownValid: boolean = true;
  PostCodeValid: boolean = true;
  EmailValid: boolean = true;
  MobileValid: boolean = true;
  NewBooking = {} as any;

  EmailFormatValid: boolean = true;
  MobileFormatValid: boolean = true;

  stateOptions: any[];
  statePayOptions: any[];
  stateOptionsTerms: any[];
  stateGenderOptions: any[];
      

  ProvideMinimalPersonalData: boolean = false;

  BookingResponse: any;

  //payment
  paymentAmount: number;
  maxPayment: number;

  searchCriteria: string;
  customersResult: any;

  disgnosticsMode: boolean = true;
  ShowCustomerDetails: boolean = false;

  makeABookingHeader: string = "Make a Booking";
  MinimalCustomerDetails: boolean = false;
  BookingID: string = "";
  publicImageURL: string = "";

  cardNumber:string = "";
  expiryDate:string = "";
  cvCode:string = "";

  EmailSMSConfirmation: string = "";

  ExtrasButtonName: string = "Next";

  bookingPayment: string = "";
  bookingExtras:string = "None";

  PIReponse: any;
  PIRequest: cPirequest;

  PackageHasExtras: boolean = false;

  paymentLocked: boolean = true;
  PaymentInProgress: boolean = false;

  AdmissionsList: string = "";
  paymentError: string = "";
  imageWidth: number = 150;
  imageHeight: number = 150;

  defaultColour: string = "#ffff00";

  httpResponse: any;
  error: string;
  paymentSuccess: string;
  buttonDisabled: boolean = true;

  SelectedSeletableResource: any[] = [];
  SelectedSeletableResourceBool: boolean[] = [];

  customerSelectedResources: any[] = [];
  includesComplexResources: boolean = false;
  includesOptionalResources: boolean = false;

  systemChoosesAllResources: boolean = true;

  validselection: boolean[] = [];

  ReturnedPaymentSecret:any;

  formVisible:boolean = false;

  AgreeToTerms: boolean = false;
  emailMarketing: boolean = false;
  smsMarketing: boolean = false;

  displayModalTerms: boolean = false;

  TermsAndConditions: string = "";
  totalAdmissions: number;

  totalAdmissionInRange: boolean;

  packageGroups: any[];
  checked: any[] = [];

  BookingHasValue: boolean = false;

  MustIncludePass: boolean = true;
  BookingSummary: bookingsummary;
  ReturnedJWT: string = "";

  TotalExtras: number = 0;
  ReferralCode: string = "";
  GoogleTagManagerID: string = "";
  RyftSubAccountID: string = "";

  tandcsValid: boolean = false;
  showtandcerror: boolean = false;
  hubConnection: HubConnection;
  
  psid: string = "";

  totalValueOfBooking: number = 0;

  showDiscount: boolean = false;
  showVoucher: boolean = false;

  discountCode: string = "";
  voucherCode: string = "";

  BookingTimeElasped: boolean = false;

  AvailabilityReq = {} as AvailabilityReq;

  SignalRFailed:boolean = false;

  timeoutTime: number = 999999;

  returnPage: string = "";

  VenueCSS: any;
  StyleSheet: string = "";
  showJustTandCs:boolean = false;

  BookingConfirmationSummary: string = "";

  BookingConfirmationText: string = "";
  TempConfirmation: boolean = false;

  version: string = environment.version;
  
  timeRemaining$: any;
  
  // timeRemaining$ = timer(0, 1000).pipe(
  //   map(n => (this.timeoutTime - n) * 1000),
  //   takeWhile(n => n >= 0),
  // );

  venueName: string = "";

  PauseTimerEffect:boolean = false;

  backgroundcolor: any;

  stateOptionAllergy: any[];
  birthdayitems: birthdayanswers[] = [];

  mustanswerallergies: boolean[] = [];
  mustanswerbirthdays: boolean[] = [];
  mustanswernumber: boolean[] = [];
  mustanswerfreetext: boolean[] = [];
  mustanswermultichoicesingleanswer: boolean[] = [];
  mustanswermultichoicemultianswer: boolean[] = [];
  mustanswerdate: boolean[] = [];

  questionAnswers: questionanswers[] = [];
  questionAnswer: questionanswers;

  bookingFreeTextAnswer: string = "";
  bookingallergyanswer: string = "";
  bookingnumberanswer: number = 0;
  bookingdateanswer: Date = null;
  allergyAnswer:boolean[] = [];
  bookingmultichoicesingleanswer: bookingquestionitem = null;
  bookingmultichoicemultianswer: bookingquestionitem[] = [];

  passeddate: string = "";

  CurrentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0,0,0,0);

  voucherpin: string = "";

  PBWStartTime: Date;

  ActualTimeRemaining: number = 0;

  UniqueUserID: string = "";
  totalAdmissionsPrice: number = 0;

  allAnswersValid: boolean = true;

  autoChooseTime: boolean = false;
  autoChooseTimeValue: string = "";

  SelectedPackageGroup: any;

  linkedAdmissionMessageShown: boolean = false;
  showWarning: boolean = false;
  WarningMessage: string = "";

  showVCWarning: boolean = false;
  VCWarningMessage: string = "";

  totalDiscount: number = 0;

  venueClosings: any[] = [];

  jbook: bookingJWT = {} as bookingJWT;

  noDepositBooking: boolean = false;

  tag0: string = "";
  tag1: string = "";
  tag2: string = "";
  tag3: string = "";
  tag4: string = "";

  packageGroupId: string = "";
  packageGroupName: string = "";
  linkid: string = "";

  ReturnURL = "";

  Link: any;

  ShowAge: boolean = false;
  ShowDOB: boolean = false;
  ShowGender: boolean = false;
  ShowInterests: boolean = false;

  showTimer = false;

  caldays: any[] = [];
  calmonths: any[] = [];
  calyears: any[] = [];

  DOBDay: any[] = [];
  DOBMonth: any[] = [];
  DOBYear: any[] = [];

  DOBIsValid: boolean = true;
  dobage: any[] = [];
  imageReturnURL: string = "";

  PBWStyle: string = "DATEFIRST";

  invalidDates: Date[] = [];

  UseIntervalAvailability: boolean = false;

  minDate: Date = new Date();


  //#endregion
  
  CheckValue()
  {
    let StartingTimeAmount = this.timeoutTime * 1000
    let NowTime = new Date().getTime();
    this.ActualTimeRemaining = this.PBWStartTime.getTime() - NowTime + StartingTimeAmount;

    if (this.ActualTimeRemaining <= 0) 
    {
      if(!this.PauseTimerEffect)
      {
        this.BookingTimeElasped = true;
      }
    }
  }

  doLinkedAdmissions()
  {
    let totalNumberOfLinked = 0;

    this.SelectedAdmissions.forEach(adm => {

      if(adm.linkedadmissions != null)
      {
        adm.linkedadmissions.forEach(ladm => {
          let linkedAdm = this.SelectedAdmissions.find(a => a.admissionid == ladm.admissionid);

          if(linkedAdm != null)
          {
            totalNumberOfLinked += Number(linkedAdm.noofpeople);
          }
        });

        if(adm.onlysetmax)
        {
          adm.max = Number(totalNumberOfLinked);

          if (adm.max < adm.min)
          {
            adm.max = adm.min;
          }

          if(adm.noofpeople > adm.max)
          {
            adm.noofpeople = Number(adm.max);
          }

          adm.fixed = false;
        }
        else
        {
          adm.noofpeople = Number(totalNumberOfLinked);
          adm.fixed = true;
        }
      }
    });

  }

  doLinkedDiscounts()
  {
    this.totalDiscount = 0;

    let totalNumberOfLinkedDiscounts = 0;

    this.SelectedAdmissions.forEach(adm => {

      if(adm.linkeddiscounts != null)
      {
        adm.linkeddiscounts.forEach(ladm => {
          let linkedDis = this.SelectedAdmissions.find(a => a.admissionid == ladm.admissionid);

          if(linkedDis != null)
          {
            if(linkedDis.noofpeople >= ladm.threshold)
            {
              let numberOfPeopleForDiscount = Math.floor(linkedDis.noofpeople / ladm.threshold);

              if(adm.noofpeople < numberOfPeopleForDiscount)
              {
                numberOfPeopleForDiscount = adm.noofpeople;
              }

              this.totalDiscount += Number(adm.price * numberOfPeopleForDiscount) * Number(ladm.percentagediscount / 100);
            }
          }
        });
      }
    });
  }
  
  WarningContinue(){
    this.showWarning = false;
  }

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    private busyService: BusyService,
    private renderer: Renderer2,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private viewportScroller: ViewportScroller) { 

      //this.minDate = new Date(this.minDate.setDate(this.minDate.getDate()));

      this.PBWStartTime = new Date();
      this.ActualTimeRemaining = 0;

      //check for window closing
      window.onbeforeunload = () => this.AbandonBooking();

      window['dataLayer'] = window['dataLayer'] || {};

      this.stateOptions = [{ label: 'Use full personal details', value: false }, { label: 'Use limited personal details', value: true }];  
      this.stateOptionsTerms = [{ label: 'No', value: false }, { label: 'Yes', value: true }];
      this.stateGenderOptions =  [{ label: 'Rather not say', value: 'NOT STATED' }, { label: 'Male', value: 'MALE'}, {label: 'Female', value: 'FEMALE'}]; 

      this.stateOptionAllergy = [{ label: 'Yes', value: true }, { label: 'No', value: false}];  

      this.dobage = [
        {name:'0 to 3 months', value:'0 to 3 months'},
        {name:'4 to 6 months', value:'4 to 6 months'},
        {name:'6 to 12 months', value:'6 to 12 months'},
        {name:'1', value:'1'},
        {name:'2', value:'2'},
        {name:'3', value:'3'},
        {name:'4', value:'4'},
        {name:'5', value:'5'},
        {name:'6', value:'6'},
        {name:'7', value:'7'},
        {name:'8', value:'8'},
        {name:'9', value:'9'},
        {name:'10', value:'10'},
        {name:'11', value:'11'},
        {name:'12', value:'12'},
        {name:'13', value:'13'},
        {name:'14', value:'14'},
        {name:'15', value:'15'},
        {name:'16', value:'16'},
        {name:'17', value:'17'},
        {name:'18', value:'18'},
        {name:'Over 18', value:'Over 18'}
      ];

      this.caldays =  [
        {name: '01', value:'01'},
        {name: '02', value:'02'},
        {name: '03', value:'03'},
        {name: '04', value:'04'},
        {name: '05', value:'05'},
        {name: '06', value:'06'},
        {name: '07', value:'07'},
        {name: '08', value:'08'},
        {name: '09', value:'09'},
        {name: '10', value:'10'},
        {name: '11', value:'11'},
        {name: '12', value:'12'},
        {name: '13', value:'13'},
        {name: '14', value:'14'},
        {name: '15', value:'15'},
        {name: '16', value:'16'},
        {name: '17', value:'17'},
        {name: '18', value:'18'},
        {name: '19', value:'19'},
        {name: '20', value:'20'},
        {name: '21', value:'21'},
        {name: '22', value:'22'},
        {name: '23', value:'23'},
        {name: '24', value:'24'},
        {name: '25', value:'25'},
        {name: '26', value:'26'},
        {name: '27', value:'27'},
        {name: '28', value:'28'},
        {name: '29', value:'29'},
        {name: '30', value:'30'},
        {name: '31', value:'31'}
      ];
          
      this.calmonths = [
        {name: '01', value:'01'},
        {name: '02', value:'02'},
        {name: '03', value:'03'},
        {name: '04', value:'04'},
        {name: '05', value:'05'},
        {name: '06', value:'06'},
        {name: '07', value:'07'},
        {name: '08', value:'08'},
        {name: '09', value:'09'},
        {name: '10', value:'10'},
        {name: '11', value:'11'},
        {name: '12', value:'12'}
      ];

      this.invalidDates.push(new Date(2024, 10, 24));
      
      for(let i = 2050; i > 1920; i--)
      {
        let thisYear = new Date().getFullYear();
        if(i <= thisYear)
        {
          this.calyears.push({name: i.toString(), value: i.toString()});
        }
      }

      this.route.params.subscribe(params => {
        let param = params['venue'];

        this.http.get(bmxurl + "/host/" + param).subscribe(response => {
          
          this.httpResponse =  (response as host_returndata);

          if((response as host_returndata).succeeded)
          {
            if(this.httpResponse.data != null)
            {
              this.venueName = this.httpResponse.data.venuename;
              localStorage.setItem("SmartVenueName", param);
              this.VenueID = this.httpResponse.data.venueid;
              localStorage.setItem("SmartVenueID", this.VenueID);
              this.titleService.setTitle("Date - " + this.venueName);

              let tempCSS = bmxurl + "/css/" + this.VenueID;
              this.VenueCSS = this.sanitizer.bypassSecurityTrustResourceUrl(tempCSS);

            }
            else
            {
              this.VenueID = "";
            }

      //get params
      this.route.params.subscribe(params => {
        this.ReferralCode = params['ReferralCode'] ? params['ReferralCode'] : "";
        this.passeddate = params['date'] ? params['date'] : "";
      });

      this.route.queryParams.subscribe(qparams => {  
        this.linkid = qparams['LinkId'] ? qparams['LinkId'] : "";
      });

      this.packageGroups = [];
      this.NewBooking.total = 0;

      this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
      this.httpResponse =  (response as venuesettings_returndata)
      
      if(this.httpResponse.succeeded)
      {
        //Set Venue Settings ID
        localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
    
        this.httpResponse.data.venueSettings.forEach(element => {
          localStorage.setItem(element.name, element.value);

          if(element.name == "MinimalDetails")
            this.MinimalCustomerDetails = element.value == "true" ? true : false;
          if(element.name == "PublicImgURL")
            this.publicImageURL = element.value;
          if(element.name == "PublicImageheight")
            this.imageHeight = element.value;
          if(element.name == "PublicImageWidth")
            this.imageWidth = element.value;
          if(element.name == "ImageReturnURL")
            this.imageReturnURL = element.value;
          if(element.name == "DefaultColour")
            this.defaultColour = element.value;
          if(element.name == "EMailSMSConfirmation")
            this.EmailSMSConfirmation = element.value;
          if(element.name == "GoogleTagManagerID")
            this.GoogleTagManagerID = element.value;
          if(element.name == "RyftSubAccountID")
            this.RyftSubAccountID = element.value;
          if(element.name == "returnPage")
            this.returnPage = element.value;
          if(element.name == "PBWStyle")
            this.PBWStyle = element.value;
          if(element.name == "UseIntervalAvailability")
            this.UseIntervalAvailability = element.value == "true" ? true : false;
        });

        this.ReturnURL = this.returnPage.toLowerCase().replace("http://","").replace("https://","")
        this.ReturnURL = "https://" + this.ReturnURL;

        this.setGtmId(this.GoogleTagManagerID);

        this.ProvideMinimalPersonalData = this.MinimalCustomerDetails;
            
        if(this.passeddate != "")
        {
          this.SelectedDate = new Date(this.passeddate);
        }

        if(this.packages == null)
          this.packages = [];

          this.http.get(bmxurl + "packagelist/" + this.VenueID).subscribe(response => {
            this.packages1 = (response as Package_returndata).data
      
            this.packages1.forEach(pkg => {
      
              let PackageStartDate: Date = new Date(pkg.startDate);
              let PackageEndDate: Date = new Date(pkg.endDate);
              let SelectedDate: Date = new Date(this.SelectedDate);

              if(PackageStartDate <= SelectedDate && PackageEndDate >= SelectedDate)
                {
                  this.packages.push(pkg);
                }

            });

            this.packages1.forEach(element => {
              if(element.packageId == this.NewBooking.packageId)
              {
                  this.SelectedPackage = element;
              }
            });

            if(this.passeddate != "")
            {
              this.doneDate();
            }
      
          }, error => {
            console.log(error);
          })

              //Check if this is a passed booking.
        if(localStorage.getItem("EventBookingPassed") != null)
        {
          this.setTab(Sections.Time);
          
          this.autoChooseTime = true;

          //var passedSelectedEvent: cEvent = (JSON.parse(localStorage.getItem("EventBookingSelectedEvent")) as cEvent);
          var passedSelectedTime: string = localStorage.getItem("EventBookingSelectedTime").toString();
          var passedSelectedDate: string = localStorage.getItem("EventBookingSelectedDate").toString();

          this.autoChooseTimeValue = passedSelectedTime;
    
          var passedSelectedPackage: cPackage = (JSON.parse(localStorage.getItem("EventBookingSelectedPackage")) as cPackage);
          var passedSelectedAdmissions: cAdmission[] = (JSON.parse(localStorage.getItem("EventBookingSelectedAdmissions")) as cAdmission[]);
          var passedSelectedResources: cResource[] = (JSON.parse(localStorage.getItem("EventBookingCustomerSelectedResources")) as cResource[]);

          this.SelectedPackage = passedSelectedPackage;
          this.customerSelectedResources = passedSelectedResources;

          this.SelectedAdmissions = [];
    
          passedSelectedAdmissions.forEach(adm => {
            this.SelectedAdmissions.push(adm);
          });
    
          this.SelectedDate = new Date(passedSelectedDate);
    
          this.titleService.setTitle("Time - " + this.venueName);

          this.CalendarHeader = "You selected: " + this.SelectedDate.toDateString();
          this.PackageHeader = "You selected: " + this.SelectedPackage.name;
          let AdmissionsList: string = "";

          this.SelectedAdmissions.forEach(adm => {
            AdmissionsList += adm.noofpeople + " x " +  adm.name + ", ";
          });

          if(AdmissionsList.length > 2)
          AdmissionsList = AdmissionsList.substring(0, AdmissionsList.length - 2);

          this.AdmissionHeader = "You selected: " + AdmissionsList;

          localStorage.removeItem("EventBookingPassed");
          localStorage.removeItem("EventBookingSelectedTime");
          localStorage.removeItem("EventBookingSelectedDate");
          localStorage.removeItem("EventBookingSelectedPackage");
          localStorage.removeItem("EventBookingSelectedAdmissions");
          localStorage.removeItem("EventBookingCustomerSelectedResources");
    
          this.sumAdmissions();
          this.getAvailability();
        }
        else
        {
          if(this.linkid != "" && this.linkid != null)
          {
            this.http.get(bmxurl + "Link/" + this.VenueID + "/" + this.linkid).subscribe(response => {
              this.httpResponse = (response as link_returndata)
                this.Link = this.httpResponse.data;

                if(this.Link != null)
                {
                  if((new Date(this.Link.expirydate) < new Date()) && (new Date(this.Link.expirydate).getFullYear() > 1901))
                  {
                    this.messageService.add({severity:'error', summary:'Expired', detail: 'The link you have tried to use has expired.'});
                  }
                  else
                  {
                    if(this.Link.packagegroup != null)
                    {
                      this.SelectedDate = new Date(this.Link.datetobook);

                      this.SelectedPackageGroup = this.Link.packagegroup;

                      this.doneDate();
                    }
                    else
                    {
                      this.SelectedDate = new Date(this.Link.datetobook);

                      this.SelectedPackage = this.Link.package;
            
                      this.CalendarHeader = "You selected: " + this.SelectedDate.toDateString();
                      this.PackageHeader = "You selected: " + this.SelectedPackage.name;

                      if(this.Link.timetobook != "")
                      {
                        this.autoChooseTime = true;
                        this.autoChooseTimeValue = this.Link.timetobook
                      }
            
                      this.donePackage(this.SelectedPackage);
                    }
                  }
                }
                else
                {
                  this.SetPageTitle("Make a Booking");

                  if(this.PBWStyle == "DATEFIRST")
                    {
                      this.dateSelected = true;
                      this.setTab(Sections.Date);
                    }
                    else
                    {
                      this.packageSelected = true;
                      this.setTab(Sections.Package);
                    }
                }
            });
          }
          else
          {
            this.SetPageTitle("Make a Booking");

            if(this.PBWStyle == "DATEFIRST")
            {
              this.dateSelected = true;
              this.setTab(Sections.Date);
            }
            else
            {
              if(this.PBWStyle=="PACKAGEFIRST")
                {
                  this.http.get(bmxurl + "PackageGroupList/" + this.VenueID + "/false"
                    ).subscribe(response => {
                      this.packageGroups = (response as packagegroups_returndata).data;
                
                      //select first package group
                      if(this.packageGroups.length > 0)
                      {
                        this.checked[this.packageGroups[0].packagegroupid] = true;
                        this.changedPGSelection(this.packageGroups[0])
                      }
                      
                    }, error => {
                      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Package Groups.'});
                    })
                }

              this.packageSelected = true;
              this.setTab(Sections.Package);
            }
          }
        }
      }
      else{
        //this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
      }
    }, error => {
      //this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
    });

          }

        }, error => {
          console.log(error);
        })
      });

      if(localStorage.getItem("UniqueUserID") == null)
      {
        this.UniqueUserID = uuidv4();
        localStorage.setItem("UniqueUserID",this.UniqueUserID);
      }
      else
      {
        this.UniqueUserID = localStorage.getItem("UniqueUserID");
      }
  
  }

  addBirthdayRow(){


    let INDEX = this.birthdayitems.length;

        this.birthdayitems.push({
          index: INDEX, 
          id: uuidv4(), 
          name: "", 
          age: "", 
          birthdate: "",
          gender: "NOT STATED",
          interests: "",
          showage: this.ShowAge,
          showdob: this.ShowDOB,
          showgender: this.ShowGender,
          showinterests: this.ShowInterests,
          dobday: "",
          dobmonth: "",
          dobyear: ""
        });

  }

  deleteBirthdayRow(inId){
    let index = 0;  
    this.birthdayitems.forEach(element => {
      if(element.id == inId)
      {
        this.birthdayitems.splice(index, 1);
      }
      index++;
    });
  }

  doneBookingQuestions(){
    let AllValid = true;

    this.SelectedPackage.questionnaire.bookingquestions.forEach(question => {
      if(question.questiontype == "ALLERGY")
      {
        if(question.answers.allergyanswer.allergypresent == true)
        {
          if(question.answers.allergyanswer.allergytext == "" && question.mandatory)
          {
            AllValid = false;
            this.mustanswerallergies[question.listorder] = false;
          }
          else
          {
            this.mustanswerallergies[question.listorder] = true;
          }
        }
        else
        {
          this.mustanswerallergies[question.listorder] = true;
        }
      }
      else if(question.questiontype == "BIRTHDAY")
      {
        this.mustanswerbirthdays[question.listorder] = true;

        this.birthdayitems.forEach(birthday => {

          if(birthday.showdob) 
          {
            let Year = birthday.dobyear;
            let Month = birthday.dobmonth;
            let Day = birthday.dobday;
  
            birthday.birthdate = birthday.dobday + "/" + birthday.dobmonth + "/" + birthday.dobyear;
          }
          else
          {
            birthday.birthdate = "";
          }

          if(birthday.name == "" || ((birthday.interests == "" || birthday.interests == null)  && birthday.showinterests) || ((birthday.age == "" || birthday.age == null)  && birthday.showage) || ((birthday.birthdate == "" || birthday.birthdate == null) && birthday.showdob))
            {
              AllValid = false;
              this.mustanswerbirthdays[question.listorder] = false;
            }
        });
      }
      else if(question.questiontype == "NUMBER")
      {
        if(question.answers.numberanswer == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswernumber[question.listorder] = false
        }
        else
        {
          this.mustanswernumber[question.listorder] = true
        }
      }
      else if(question.questiontype == "FREETEXT")
      {
        if((question.answers.freetextanswer == null || question.answers.freetextanswer == "") && question.mandatory)
        {
          AllValid = false;
          this.mustanswerfreetext[question.listorder] = false;
        }
        else
        {
          this.mustanswerfreetext[question.listorder] = true;
        }
      }
      else if(question.questiontype == "DATE")
      {
        if(question.answers.dateanswer == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswerdate[question.listorder] = false;
        }
        else
        {
          this.mustanswerdate[question.listorder] = true;
        }
      }
      else if(question.questiontype == "MULTICHOICESINGLE")
      {
        if(question.answers.multichoicesingleanswer.id == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswermultichoicesingleanswer[question.listorder] = false;
        }
        else
        {
          this.mustanswermultichoicesingleanswer[question.listorder] = true;
        }

      }
      else if(question.questiontype == "MULTICHOICEMULTIPLE")
      {
        if(question.answers.multichoicemultianswer.length == 0 && question.mandatory)
        {
          AllValid = false;
          this.mustanswermultichoicemultianswer[question.listorder] = false;
        }
        else
        {
          this.mustanswermultichoicemultianswer[question.listorder] = true;
        }

      }
    });
   
    if(AllValid)  
    {
      this.SelectedPackage.questionnaire.bookingid = this.NewBooking.bookingId;
      this.SelectedPackage.questionnaire.bookingtoken = this.ReturnedJWT;
      
      this.SelectedPackage.questionnaire.bookingquestions.forEach(question => {
        if(question.questiontype != "ALLERGY")
        {
          question.answers.allergypresent = false;
          question.answers.allergytext = "";
          question.answers.allergyanswer.allergypresent = false;
        }
        
        if(question.questiontype == "BIRTHDAY")
          question.answers.birthdayanswers = this.birthdayitems;
      });

      this.http.post<booking_returndata>(bmxurl + "/questionanswers", this.SelectedPackage.questionnaire).subscribe(response => {
        this.httpResponse = response
        if (this.httpResponse.succeeded == true) {
          //this.SetupPayment();
          //this.titleService.setTitle("Payment - " + this.venueName);
          //this.setTab(Sections.Payment);

          this.http.get<package_returndata>(bmxurl + "package/" + this.VenueID + "/" + this.NewBooking.packageId).subscribe(response => {
            let BookedPackage: any = (response as package_returndata).data;
             if(BookedPackage.publicDepositPolicy.type == "NoDeposit")
             {
              
              this.http.get<package_returndata>(bmxurl + "nodeposit/" + this.VenueID + "/" + this.NewBooking.bookingId + "/" + this.ReturnedJWT).subscribe(response => {
                if(response.succeeded)
                {
                  this.DoSummary(this.BookingResponse);
                  this.noDepositBooking = true;
                  this.BookingComplete = true;
          
                  this.BookingConfirmationSummary = "Booking Confirmation";
                  this.BookingConfirmationText = "Your booking has been confirmed, please lookout for your confirmation email further information, we look forward to seeing you.";
                }
                else
                {
                  this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting creating booking'});
                }
              });
  
             }
             else
             {
              // this.SetupPayment();
              // this.titleService.setTitle("Payment - " + this.venueName);
              // this.setTab(Sections.Payment);

              if(!this.PackageHasExtras)
                {
                  this.setTab(Sections.Customer);
                }
                else
                {
                  this.setTab(Sections.Extras);
                }
             }
          });

        }
        else
        {
          this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.messages[0] });
      })
    }
  }

  ngOnDestroy() {
    this.AbandonBooking();
  }

  setGtmId(gtmId: string) {
    const scriptElement = this.renderer.createElement('script');
    scriptElement.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + gtmId + "');"
    
    const headElement = this.renderer.selectRootElement('head2');
    this.renderer.appendChild(headElement, scriptElement);
  }

  setCSS() {
    const stylesheetElement = this.renderer.createElement('stylesheet');
    stylesheetElement.innerHTML = "body {background-color: #F23 !important;}"
    
    const headElement = this.renderer.selectRootElement('head2');
    this.renderer.appendChild(headElement, stylesheetElement);
  }

  DoSummary(BookingSummary)
  {
    this.titleService.setTitle("Summary - " + this.venueName);

    let admissionsText: string = "";
    let extrasText: string = "";

    BookingSummary.resources[0].admissions.forEach(adm => {
      if( adm.noofpeople > 0)
      {
        admissionsText += adm.noofpeople + " x " +  adm.name + ", ";
      }
    });

    if(admissionsText.length > 2)
    admissionsText = admissionsText.substring(0, admissionsText.length - 2);

    // if(BookingSummary.extras != null)
    // {
    //   if(BookingSummary.extras.length > 0)
    //   {
    //     BookingSummary.extras.forEach(element => {
    //       if(element.qty > 0)
    //         extrasText += element.name + " x " + element.qty + ", ";
    //     });
    //   }
    // }


    if(BookingSummary.extras != null)
      {
        if(BookingSummary.extras.length > 0)
        {
          BookingSummary.extras.forEach(element => {
            if(element.qty > 0)
            {
              if(element.answer != null && element.answer != "")
              {
                extrasText += element.name + " x " + element.qty + " (" + element.question + ": " + element.answer + "), ";
              }
              else
              {
                extrasText += element.name + " x " + element.qty + ", ";
              }
            }
          });
        }
      }

    if(extrasText.length > 2)
    {
      extrasText = extrasText.substring(0, extrasText.length - 2);
    }

    let CustomerName = "";
    let CustomerAddress1 = "";
    let CustomerAddress2 = "";
    let CustomerTownCity = "";
    let CustomerPostCode = "";
    let FullCustomer = "";

    CustomerName = BookingSummary.customer.fullName.trim();

    if (BookingSummary.customer.address1 != null && BookingSummary.customer.address1 != "") 
    {
      CustomerAddress1 = BookingSummary.customer.address1 = ", "
    }
    
    if (BookingSummary.customer.address2 != null && BookingSummary.customer.address2 != "") 
    {
      CustomerAddress2 = BookingSummary.customer.address2 = ", "
    }

    if (BookingSummary.customer.townCity != null && BookingSummary.customer.townCity != "") 
    {
      CustomerTownCity = BookingSummary.customer.townCity = ", "
    }

    if (BookingSummary.customer.postCode != null && BookingSummary.customer.postCode != "") 
    {
      CustomerPostCode = BookingSummary.customer.postCode = ", "
    }

    FullCustomer = CustomerName + CustomerAddress1 + CustomerAddress2 + CustomerTownCity + CustomerPostCode;

    let newBookingSummary:cBookingsummary = {
      Disabled: BookingSummary.disabled,
      bookingId: BookingSummary.bookingId,
      bookingIdHR: BookingSummary.bookingIdHR,
      admissions: admissionsText,
      startTime: BookingSummary.startTime,
      endTime: BookingSummary.endTime,
      name: BookingSummary.name,
      arrival: BookingSummary.arrival,
      guests: BookingSummary.guests,
      total: BookingSummary.total,
      outstanding: BookingSummary.outstanding,
      paid: Number(BookingSummary.total) - Number(BookingSummary.outstanding),
      totaldiscounts: Number(BookingSummary.totaldiscounts),
      bookingfee: Number(BookingSummary.bookingfee),
      customerName: BookingSummary.customer.fullName,
      customerAddress: FullCustomer,
      customerMobile: BookingSummary.customer.mobile,
      customerEmail:  BookingSummary.customer.email,
      packageName:  BookingSummary.packageName,
      publicBooking: true,
      extras: extrasText,
      itinerary: BookingSummary.itinerary,
      itineraryEX: BookingSummary.itineraryEX,
      notes: BookingSummary.notes

    }

    this.BookingSummary = newBookingSummary;
    
    this.BookingComplete = true;
  }

  changedPGSelection(selectedButton){
    this.packages = [];

    let gtmPackages: any[] = [];

    this.packageGroups.forEach(element => {
      if(this.checked[element.packagegroupid] != null)
        this.checked[element.packagegroupid] = false;
    });

    this.checked[selectedButton.packagegroupid] = true;

    this.packageGroups.forEach(pg => {
      if(this.checked[pg.packagegroupid] != null)
      {
        if(this.checked[pg.packagegroupid] == true)
        {
          pg.packages.forEach(packagesInPG => {

            let found = false;  
            this.packages.forEach(pkg => {
              if(pkg.packageId == packagesInPG.packageId)
                found = true;
            });

            if(!found)
              this.packages.push(packagesInPG)
          });
        }
      }
    });

    let dicountapplied = "";
    let discountamount = 0;
    
    this.packages.forEach(pkg => {

      if(this.IsDiscountValid(pkg))
        {
          dicountapplied = "Early Bird Discount";
          discountamount = selectedButton.earlybirddiscountamount;
        }
        else
        {
          dicountapplied = "";
          discountamount = 0;
        }

        this.packageGroupId = selectedButton.packagegroupid;
        this.packageGroupName = selectedButton.packagegroupname;

      let gtmPackage: any = {
        'item_id': pkg.packageId,
        'item_name': pkg.name,
        'affiliation': this.VenueID,
        'coupon': dicountapplied,
        'discount': discountamount,
        'index': 0,
        'item_brand': "",
        'item_category': "",
        'item_category2': "",
        'item_category3': "",
        'item_category4': "",
        'item_category5': "",
        'item_list_id': this.packageGroupId,
        'item_list_name': this.packageGroupName,
        'item_variant': "",
        'location_id': this.VenueID,
        'price': 0,
        'quantity': 1
      }
      
      gtmPackages.push(gtmPackage);
    });


    let gtmdata = {
      'item_list_id': this.packageGroupId,
      'item_list_name': this.packageGroupName,
      'items': gtmPackages
    }
    
    this.trackEvent("view_item_list", gtmdata);

  }

  addMs = (date: Date, ms: number): Date => {
    const result = new Date(date);
    result.setMilliseconds(result.getMilliseconds() + ms);
    return result;
  };

  sumExtras(){
    this.TotalExtras = 0;

    this.packageExtras.forEach(element => {
      this.TotalExtras += Number(element.price) * Number(element.qty);
    });

    this.NewBooking.total = this.totalAdmissionsPrice + this.TotalExtras;
  }

  sumAdmissions(){

    this.doLinkedDiscounts();

    this.doLinkedAdmissions();

    this.totalAdmissions = this.SelectedAdmissions.map(a => a.noofpeople).reduce(function(a, b)
    {
      return Number(a) + Number(b);
    });

    if((this.totalAdmissions > this.SelectedPackage.max && this.SelectedPackage.max > 0) || (this.totalAdmissions < this.SelectedPackage.min && this.SelectedPackage.min > 0))
    {
      this.totalAdmissionInRange = false;
    }
    else
    {
      this.totalAdmissionInRange = true;
    }

    this.totalAdmissionsPrice = 0;

    this.SelectedAdmissions.forEach(adm => {  
      if(adm.fixedPrice)
      {
        this.totalAdmissionsPrice = this.totalAdmissionsPrice + Number(adm.price);
      }
      else
      {
        this.totalAdmissionsPrice = this.totalAdmissionsPrice + Number(adm.price) * Number(adm.noofpeople);
      }
    });

    this.NewBooking.total = this.totalAdmissionsPrice - this.totalDiscount;
  }

  donePartyRooms()
  {
    this.SelResourceHeader = "You selected: ";
    this.customerSelectedResources = [];
    
    let index = 0;
    this.SelectedResources.forEach(packRes => {
      if(packRes.optional == true)
      {
        if(this.SelectedSeletableResourceBool[index] == true)
        {
          packRes.ignore = false;
          if(packRes.resources.length > 0)
          {
              if(packRes.isconstructed && packRes.isresourcecontainer)
              {
                let resourceToSave: resource;
                packRes.resources.forEach(selectedRes => {
                  if(selectedRes != null)
                  {
                    if (selectedRes.id == this.SelectedSeletableResource[index])
                    {
                      resourceToSave = selectedRes;
                    }
                  }
                });
                
                packRes.resources = [];
                packRes.resources.push(resourceToSave);
              }
              this.customerSelectedResources.push(packRes);
              this.validselection[index] = true;
          }
          else
          {
            packRes.ignore = false;
            this.customerSelectedResources.push(packRes);
            this.SelResourceHeader += packRes.name + ", ";
          }
        }
        else
        {
          packRes.ignore = true;
          this.customerSelectedResources.push(packRes);
        }
      }
      else
      {
        if (this.SelectedSeletableResource[index] != null)
        {
          //if(packRes.selectionmode != "ANY" && packRes.selectionmode != "SYSTEMANY")
          //{
            let resourceToSave: resource;
            packRes.resources.forEach(selectedRes => {
              if (selectedRes.id == this.SelectedSeletableResource[index])
              {
                resourceToSave = selectedRes;
              }
            });
            
            packRes.resources = [];
            packRes.resources.push(resourceToSave);

            this.customerSelectedResources.push(packRes);
            this.validselection[index] = true;
          //}
         // else
         // {
          //  this.customerSelectedResources.push(packRes);
          //  this.validselection[index] = true;
          //}
        }
        else
        {
          this.customerSelectedResources.push(packRes);
          if(packRes.mode != "SIMPLE")
            this.validselection[index] = true;
        }
      }
      index++;
    });

    let validationPassed = true;
    
    //Check validation:
    index = 0;
    this.customerSelectedResources.forEach(innerPackageRes => {
      if(innerPackageRes != null)
      {
        if(innerPackageRes.optional == true)
        {
          if (this.SelectedSeletableResourceBool[index] == true)
          {
            if(innerPackageRes.resources.length > 0)
            {
              if(innerPackageRes.resources[0] == null)
                this.validselection[index] = false;
              else
                this.validselection[index] = true;
            }
          }
          else
          {
            this.validselection[index] = true;
          }
        }
      }
      index++;
    });

    this.validselection.forEach(element => {
      if (element == false)
        validationPassed = false;
    });
    
    if (!validationPassed)
      return;


    this.SelectedPackage.resources = []

    this.customerSelectedResources.forEach(element => {
      if(!element.ignore)
      {
        this.SelectedPackage.resources.push(element);
        if(element.resources.length == 0)
        {
          if(element.mode != "SIMPLE")
            this.SelResourceHeader += element.name + ", ";
        }
        else
        {
          if(element.isresourcecontainer && !element.isconstructed)
          {
            if(element.mode != "SIMPLE")
              this.SelResourceHeader += element.name + ", ";
          }
          else
          {
            if(element.mode != "SIMPLE")
            {
              if(element.resources[0] != null)
                this.SelResourceHeader += element.resources[0].name + ", ";
            }
          }
        }
      }
    });
    

    if(this.SelResourceHeader.length > 2)
    {
      this.SelResourceHeader = this.SelResourceHeader.substring(0, this.SelResourceHeader.length - 2)
    }

    if (this.SelResourceHeader == "You selected")
    {
      this.SelResourceHeader = "You selected: Nothing";
    }


      this.setTab(Sections.Time);
      this.getAvailability();
  }

  addselectedCustomer(passCustomer){
    this.searchCriteria = "";

    this.CustomerHeader = "Customer: " +  passCustomer.firstName + "  " + passCustomer.lastName;

    let newCustomer: Customer = {
      id: null,
      customerid: null,
      firstName: passCustomer.firstName,
      lastName: passCustomer.lastName,
      fullName: passCustomer.firstName + " " + passCustomer.lastName,
      address1: passCustomer.address1,
      address2: passCustomer.address2,
      townCity: passCustomer.townCity,
      postCode: passCustomer.postCode,
      email: passCustomer.email,
      mobile: passCustomer.mobile,
      dob: this.dob,
      venueId: this.VenueID,
      disabled: false,
      bookingid: null
    }

    this.NewBooking.customer = newCustomer;

    if(this.packageExtras.length == 0)
    {
      this.setTab(Sections.Notes);
    }
    else
    {
      this.setTab(Sections.Extras);
    }

    this.customersResult = null;
    this.searchCriteria = "";
  }

  addCustomer(){
    this.ShowCustomerDetails = true;
    this.ProvideMinimalPersonalData = true;
  }

  restartTimer(inTime){
    
    this.timeRemaining$ = timer(0, 1000).pipe(
      map(n => (this.timeoutTime - n) * 1000),
      takeWhile(n => n >= 0)
    );

    this.timeRemaining$.subscribe(
      x => this.CheckValue(),
      err => null,
      () => {
        if(!this.PauseTimerEffect)
        {
          this.BookingTimeElasped = true;
        }
      }
    )
  }

  ngOnInit(): void {

    this.trackEvent("session_start", "");

  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  private SetupPayment() {
      this.paymentError = "";

      let PKKey: string = environment.PaymentAccountNumber;
      let StripeAccount = this.RyftSubAccountID;

      const stripe = Stripe(PKKey,
        {
          stripeAccount: StripeAccount
        });

      this.PIRequest = {
        amount: Math.floor(this.NewBooking.totalpublicdeposit * 100),
        currency: "GBP",
        description: "Booking - " + this.NewBooking.bookingIdHR,
        bookingid: this.NewBooking.bookingId,
        venueid: this.VenueID,
        StripeAccount: StripeAccount,
        customer: this.NewBooking.customer
      };

      this.http.post(bmxurl + "StripePI", this.PIRequest).subscribe(response => {
        this.PIReponse = (response as piresponse_returndata);

        if(this.PIReponse.succeeded)
        {
          const options = {
            layout: {
              type: 'accordion',
              defaultCollapsed: false,
            }
          };
    
          const appearance = {
            theme: 'stripe',
            labels: 'floating'
            
          };
    
          const elements = stripe.elements({clientSecret: this.PIReponse.data.clientSecret, appearance });
          const card = elements.create('payment', options);

          
          card.mount('#payment-element');
    
          card.addEventListener('change', event => {
            const displayError = document.getElementById('payment-errors');
            if (event.error) {
              displayError.textContent = event.error.message;
            } else {
              displayError.textContent = '';
            }
          });
    
          const paymentForm = document.getElementById('payment-form');
          paymentForm.addEventListener('submit', async (event) => {

            this.PauseTimerEffect = true;
            this.PaymentInProgress = true;
            event.preventDefault();
            const { error } = await stripe.confirmPayment({
              elements,
              confirmParams: {},
              redirect: "if_required"
            });
            const { paymentIntent } = await stripe.retrievePaymentIntent(this.PIReponse.data.clientSecret);
            if (paymentIntent.status == "succeeded")
            {
              this.paymentError = "";

              setTimeout(() => {

              this.jbook = {
                bookingid: this.NewBooking.bookingId,
                jwt: this.ReturnedJWT
              }
      
              this.http.post(bmxurl + "PublicBooking", this.jbook).subscribe(response => {
                this.BookingResponse = (response as booking_returndata).data;
                //this.trackEvent("add_payment_info", this.BookingResponse.paid);
                
                if(this.BookingResponse.payments != null)
                {
                  if(this.BookingResponse.payments.length > 0)
                  {
                    this.BookingConfirmationSummary = "Booking Confirmed";
                    this.BookingConfirmationText = "Your booking has been confirmed, please lookout for your confirmation email for further information, we look forward to seeing you.";
      
                    this.BookingComplete = true;
                    this.PaymentInProgress = false;

                    this.DoSummary(this.BookingResponse);
                    this.hubConnection.stop();

                    //this.sendEmailConfirmation();

                    this.DoGTM();
                  }
                  else
                  {
                    this.BookingConfirmationSummary = "Booking In Progress";
                    this.BookingConfirmationText = "Your booking has been received and your payment is being processed, please lookout for your confirmation email for further information, if you do not receive your confirmation within 24 hours, please contact the venue";
            
                    this.BookingComplete = true;
                    this.PaymentInProgress = false;

                    this.DoSummary(this.BookingResponse);
                    this.hubConnection.stop();

                    this.DoGTM();
                  }
                }
                else
                {
                  this.BookingConfirmationSummary = "Booking In Progress";
                  this.BookingConfirmationText = "Your booking has been received and your payment is being processed, please lookout for your confirmation email further information, if you do not receive your confirmation within 24 hours, please contact the venue";
          
                  this.BookingComplete = true;
                  this.PaymentInProgress = false;

                  this.DoSummary(this.BookingResponse);
                  this.hubConnection.stop();

                  this.DoGTM();
                }
              });
              }, 10000);
            }
            else
            {
              this.paymentError = "Your payment has failed, please try again.";
              paymentForm.style.removeProperty("display");
              //Start Timer
              this.PauseTimerEffect = false;
              this.PaymentInProgress = false;
              //this.SetupPayment();
            }
          });
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail: this.PIReponse.messages});
          const paymentForm = document.getElementById('payment-form');
          paymentForm.style.display = "none";
          this.PaymentInProgress = false;

          this.paymentError = "This booking cannot currently be made online, please contact the venue.";
        }

        //Set up timeout for voucher if SignalR fails
        setTimeout(() => {
          this.busyService.idle();
        }, 13000);

      }, error => {
          const paymentForm = document.getElementById('payment-form');
          paymentForm.style.display = "none";
          this.PaymentInProgress = false;

          this.paymentError = "An error has occured, please contact the venue, a payment cannot be made at this time.";
      });
  }

  DoGTM(){

    let products: any[] = [];
    let tagslist: string = "";

    if(this.SelectedPackage.Tags != null)
    {
      this.SelectedPackage.Tags.forEach(element => {
        tagslist += element.name + "_";
      });

      if (tagslist.length > 1)
        tagslist = tagslist.substring(0, tagslist.length - 1);
    }

    this.SelectedAdmissions.forEach(adm => {
      if(adm.noofpeople > 0)
      {
        let product: any = {
          'item_id': adm.admissionid,
          'item_name': this.SelectedPackage.name,
          'affiliation': this.VenueID,
          'coupon': this.NewBooking.discounts.code,
          'discount': this.NewBooking.discounts.discountamount,
          'index': 0,
          'item_brand': this.venueName,
          'item_category': this.tag0,
          'item_category2': this.tag1,
          'item_category3': this.tag2,
          'item_category4': this.tag3,
          'item_category5': this.tag4,
          'item_list_id': this.packageGroupId,
          'item_list_name': this.packageGroupName, 
          'item_variant': adm.name,  
          'location_id': this.VenueID,
          'price': adm.price,
          'quantity': adm.noofpeople,
        } 

        products.push(product);
      }
    });

    if(this.NewBooking.extras)
    {
      this.NewBooking.extras.forEach(ext => {
        if(ext.qty > 0)
        {
          let extra: any = {
            'item_id': ext.extraId,
            'item_name': ext.name,
            'item_list_id': "0",
            'item_list_name': 'Extras',
            'item_category': 'Extras',
            'price': ext.price,
            'quantity': ext.qty
          }
          products.push(extra);
        }
      });
    }

    let packageTags: any[] = [];
    if(this.SelectedPackage.tags != null)
    {
      if(this.SelectedPackage.tags.length > 0)
      {
        this.SelectedPackage.tags.forEach(tag => {
          let tagItem: any = tag.name
          packageTags.push(tagItem);
        });
      }
    }

    let DiscountCode = "";
    if(this.NewBooking.discounts != null)
    {
      if(this.NewBooking.discounts.length > 0)
      {
        DiscountCode = this.NewBooking.discounts[0].discountCode;
      }
    }


    let gtmdata = {
      'coupon': DiscountCode,
      'shipping': 0,
      'tax': 0,
      'transaction_id': this.NewBooking.bookingIdHR,
      'currency': 'GBP',
      'value': this.NewBooking.total,
      'items': products
    }

    this.trackEvent("purchase", gtmdata);
  }

  SetPageTitle(PageTitle: string){

      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push(
        {
          'event': 'gtm.historyChange',
          'title': PageTitle,
        }
      );

  }

  trackEvent(eventName: string, eventData: any) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(
      {
        'event': eventName,
        'data': eventData
      }
    );
  }

  applyCustomer(passCustomer)
  {
    this.ShowCustomerDetails = true;
    this.firstName = passCustomer.firstName;
    this.lastName = passCustomer.lastName;
    this.address1 = passCustomer.address1;
    this.address2 = passCustomer.address2;
    this.town = passCustomer.townCity;
    this.postCode = passCustomer.postCode;
    this.email = passCustomer.email;
    this. mobile = passCustomer.mobile;

    this.customersResult = null;
    this.searchCriteria = "";

  }

  // searchCustomer(){
  //   this.http.get(bmxurl + "customer/search/" + this.VenueID + "/" + this.searchCriteria).subscribe(response => {
  //     this.customersResult = (response as customer[])
  //   }, error => {
  //     console.log(error);
  //   })

  // }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  IsDiscountValid(p)
  {
    if(p.earlybirddiscount)
    {
      if(p.discountrangetype == 0)
      {
        let dstartdate: Date = new Date(p.discountstart);
        let dstartend: Date = new Date(this.addDays(new Date(p.discountend) ,1));

        if(dstartdate.getTime() <= this.CurrentDate.getTime() && dstartend.getTime() >= this.CurrentDate.getTime())
        {
          return true;
        }
        else
        {
          return false;
        }
      }
      else
      {
        let today = new Date();
        today = new Date(today.toDateString());
        let bookingdate = new Date(this.SelectedDate);
        let diff = bookingdate.getTime() - today.getTime();
        let days = diff / (1000 * 3600 * 24);
        if(days >= p.discountdaysbefore)
        {
          return true;
        }
        else
        {
          return false;
        }
      }

    }
    else
    {
      return false;
    }

  }

  doneDate()
  {
    this.packages = [];

    this.titleService.setTitle("Package - " + this.venueName);

    this.CalendarHeader = "You selected: " + this.SelectedDate.toDateString();
    //this.packages = [];

    //this.trackEvent("P_Date_Chosen", this.SelectedDate.toDateString());

    this.http.get(bmxurl + "PackageGroupList/" + this.VenueID + "/false/" + 
    ('00' + this.SelectedDate.getDate()).slice(-2)+
    ('00' + (Number(this.SelectedDate.getMonth() + 1).toString())).slice(-2)
    +this.SelectedDate.getFullYear().toString().substr(-2)
    ).subscribe(response => {
      this.packageGroups = (response as packagegroups_returndata).data;

      //select first package group
      if(this.packageGroups.length > 0)
      {
        this.checked[this.packageGroups[0].packagegroupid] = true;
        this.changedPGSelection(this.packageGroups[0])
      }
      
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Package Groups.'});
    })

    this.AbandonBooking();

    this.NewBooking.total = 0;

    let BookingDateYear = this.SelectedDate.getFullYear().toString().substr(-2);
    let BookingDateMonth = ('00' + (this.SelectedDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.SelectedDate.getDate()).slice(-2);

    let dateChosen = BookingDateDay + BookingDateMonth + BookingDateYear;

    this.http.get<venueclosings_returndata>(bmxurl + "/VenueClosingList/" + this.VenueID + "/false/" + dateChosen).subscribe(response => {
      this.venueClosings = (response as venueclosings_returndata).data;

      if(this.venueClosings != null)
      {
        this.venueClosings.forEach(element => {
          
            this.VCWarningMessage += element.publicMessage + "<BR>";
            this.showVCWarning = true;
        });
      }
      else
      {
        this.showVCWarning = false;
      }
    });

    if(this.PBWStyle == "DATEFIRST")
    {
      this.resetAfterDate()
      this.setTab(Sections.Package);
    }
    else
    {
      this.AbandonBooking();
      //this.resetAfterPackage();
      this.sumAdmissions();

      //Get day for selected date.
      let day = this.SelectedDate.getDay();

      this.packageExtras = [];

      if(this.SelectedPackage.extras != null)
      {
        switch (day)
        {
          case 0:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.sunday == true);
            break;
          case 1:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.monday == true);
            break;
          case 2:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.tuesday == true);
            break;
          case 3:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.wednesday == true);
            break;
          case 4:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.thursday == true);
            break;
          case 5:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.friday == true);
            break;
          case 6:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.saturday == true);
            break;
          default:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.sunday == true);
            break;
        }
      }

      this.packageExtras.forEach(ext => {
        ext.qty = 0;
      });

      if(this.packageExtras.length > 0)
          this.PackageHasExtras = true;


      this.setTab(Sections.Admissions);
    }
  }

  AbandonBooking() {

    //Does a current booking exist for this date?
    if (this.NewBooking.bookingId != null) {
      let abandonBooking: any = {
        bookingid: this.NewBooking.bookingId,
        jwt: this.ReturnedJWT
      };
      //Booking Exists, abandon it
      this.http.post(bmxurl + "abandonbooking/", abandonBooking).subscribe(response => {
        //this.restartTimer();
      }, error => {
        //this.messageService.add({ severity: 'error', summary: 'Failure', detail: 'Failed to abandon booking.' });
      });
    }
  }

  displayDiscount(){
    let discountname = document.getElementById("discountButton") as HTMLElement;
    discountname.blur()
    this.showDiscount = !this.showDiscount;
  }

  doneDiscount(){
    //send discount to server
    let dv: cDiscountvalidate = {
      booking: this.NewBooking,
      discountcode: this.discountCode.trim()
    }

    this.http.post<booking_returndata>(bmxurl + "/DiscountValidate", dv).subscribe(response => {
      this.httpResponse = response
      if (this.httpResponse.succeeded == true) {
        this.messageService.add({ severity: 'success', summary: 'Discount Added', detail: 'Your discount has been added' });

        this.BookingResponse = (response as booking_returndata).data;

        this.showDiscount = false;
        this.discountCode = "";

        this.showVoucher = false;
        this.voucherCode = "";

        this.NewBooking = (response as booking_returndata).data;

        if(this.NewBooking.PublicBooking)
        {
          this.paymentAmount = this.NewBooking.totalpublicdeposit;
        }
        else{
          this.paymentAmount = this.NewBooking.totalvenuedeposit;
        }

        this.totalValueOfBooking = this.NewBooking.total;

        if(this.totalValueOfBooking <= 0)
        {
          this.BookingConfirmationSummary = "Booking Confirmed";
          this.BookingConfirmationText = "Your booking has been confirmed, please lookout for your confirmation email further information, we look forward to seeing you.";

          this.BookingComplete = true;
          this.PaymentInProgress = false;
          this.noDepositBooking = true;

          this.DoSummary(this.BookingResponse);
        }
        else
        {
          //this.initialisePayment();
          this.SetupPayment();
        }
      }
      else
      {
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.messages[0] });
    })
  }

  doneVoucher()
  {  
    this.busyService.busy();

    let dv: cVouchervalidate = {
      booking: this.NewBooking,
      vouchercode: this.voucherCode.trim(),
      voucherpin : this.voucherpin 
    }

    this.http.post<booking_returndata>(bmxurl + "/VoucherValidate", dv).subscribe(response => {
      this.httpResponse = response
      if (this.httpResponse.succeeded == true) {
        this.messageService.add({ severity: 'success', summary: 'Voucher Added', detail: 'Your voucher has been added' });

        this.showDiscount = false;
        this.discountCode = "";

        this.showVoucher = false;
        this.voucherCode = "";

        this.NewBooking = (response as booking_returndata).data;
        this.BookingResponse = (response as booking_returndata).data;

        this.totalValueOfBooking = this.NewBooking.total;

        if(this.NewBooking.PublicBooking)
        {
          this.paymentAmount = this.NewBooking.totalpublicdeposit;
        }
        else{
          this.paymentAmount = this.NewBooking.totalvenuedeposit;
        }

        if(this.NewBooking.totalpayments >= this.NewBooking.totalpublicdeposit)
        {
          this.BookingConfirmationSummary = "Booking Confirmed";
          this.BookingConfirmationText = "Your booking has been confirmed, please lookout for your confirmation email further information, we look forward to seeing you.";

          this.BookingComplete = true;
          this.PaymentInProgress = false;

          this.DoSummary(this.BookingResponse);
        }
        else
        {
          this.SetupPayment();
        }

        this.busyService.idle();
      }
      else
      {
        this.busyService.idle();
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
      }
    }, error => {
      this.busyService.idle();
      this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.error.messages[0] });
    })
  }

  donePackage(inPackage)
  {
    if(inPackage.bookingtimeout == 0)
    {
      this.timeoutTime = 300;
    }
    else
    {
      this.timeoutTime = inPackage.bookingtimeout;
    }

    if(inPackage.warning != null)
    {
      if(inPackage.warning.publicmessage!=null && inPackage.warning.publicmessage != "")
      {
        this.WarningMessage = inPackage.warning.publicmessage;
        this.showWarning = true;
      }
    }

    //find package group that package belongs to
    this.packageGroups.forEach(pg => {
      pg.packages.forEach(pkg => {
        if(pkg.packageId == inPackage.packageId)
        {
          this.SelectedPackageGroup = pg;

        }
      });
    });

    this.titleService.setTitle("Admission - " + this.venueName);

    this.SelectedPackage = inPackage;

    if(this.SelectedPackage.questionnaire != null)
    {
      this.SelectedPackage.questionnaire.bookingquestions.forEach(question => {
        if(question == "BIRTHDAY")
        {
          this.ShowAge = question.showage;
          this.ShowDOB = question.showdob;
          this.ShowGender = question.showgender;
          this.ShowInterests = question.showinterests;

          this.birthdayitems.push({
            index: 0, 
            id: uuidv4(), 
            name: "", 
            age: "", 
            birthdate: "",
            gender: "NOT STATED",
            interests: "",
            showage: this.ShowAge,
            showdob: this.ShowDOB,
            showgender: this.ShowGender,
            showinterests: this.ShowInterests,
            dobday: "",
            dobmonth: "",
            dobyear: "",
          });
        }
      });
    }

    if(this.SelectedPackage.questionnaire != null)
    {
      for(var i = 0; i < this.SelectedPackage.questionnaire.bookingquestions.length; i++)
      {
        this.mustanswerallergies[i] = true;
        this.mustanswerbirthdays[i] = true;
        this.mustanswernumber[i] = true;  
        this.mustanswerfreetext[i] = true;
        this.mustanswermultichoicesingleanswer[i] = true;
        this.mustanswermultichoicemultianswer[i] = true;
        this.mustanswerdate[i] = true;
        this.allergyAnswer[i] = true;  
      }
    }


  let dicountapplied = "";
  let discountamount = 0;

  if(this.IsDiscountValid(inPackage))
    {
      dicountapplied = "Early Bird Discount";
      discountamount = inPackage.earlybirddiscountamount;
    }
    else
    {
      dicountapplied = "";
      discountamount = 0;
    }

  if(inPackage.Tags)
  {
    for(i = 0; i < inPackage.Tags.length; i++)
    {
      if(i == 0)
        this.tag0 = inPackage.Tags[i].name;
      else if(i == 1)
        this.tag1 = inPackage.Tags[i].name;
      else if(i == 2)
        this.tag2 = inPackage.Tags[i].name;
      else if(i == 3)
        this.tag3 = inPackage.Tags[i].name;
      else if(i == 4)
        this.tag4 = inPackage.Tags[i].name;
    }
  }


  let gtmPackage: any = {
    'item_id': inPackage.packageId,
    'item_name': inPackage.name,
    'affiliation': this.venueName,
    'coupon': dicountapplied,
    'discount': discountamount,
    'index': 0,
    'item_brand': "",
    'item_category': this.tag0,
    'item_category2': this.tag1,
    'item_category3': this.tag2,
    'item_category4': this.tag3,
    'item_category5': this.tag4,
    'item_list_id': this.packageGroupId,
    'item_list_name': this.packageGroupName,
    'item_variant': "",
    'location_id': this.venueName,
    'price': 0,
    'quantity': 1
  };

  let gtmdata = {
    'currency': "GBP",
    'value': 0,
    'items': gtmPackage
  }
    

    this.trackEvent("view_item", gtmdata);

    this.customerSelectedResources = [];
    this.SelectedResources = [];
    
    this.PackageHeader = "You selected: " + this.SelectedPackage.name;

    //this.trackEvent("P_Package_Chosen", this.SelectedPackage.name);

    this.SelectedAdmissions = [];

    this.SelectedPackage.admissions.forEach(adm => {
      var newAdm: admission = {
        id: adm.id,
        admissionid: adm.admissionid,
        classid: adm.classid,
        noofpeople: adm.min,
        price: adm.price,
        name: adm.name,
        index: adm.index,
        max: adm.max,
        min: adm.min,
        venueid: adm.venueid,
        disabled: adm.disabled,
        admissionClass: adm.admissionClass,
        bookingwizardtext: adm.bookingwizardtext,
        fixedPrice: adm.fixedPrice,
        participant: adm.participant,
        mustinclude: adm.mustinclude,
        uselinkeddiscounts: adm.uselinkeddiscounts,
        linkedadmissions: adm.linkedadmissions,
        linkeddiscounts: adm.linkeddiscounts,
        uselinkedadmissions: adm.uselinkedadmissions,
        maxlinkeddiscounts: adm.maxlinkeddiscounts,
        maxlinkedadmissions:adm.maxlinkedadmissions,
        fixed: adm.fixed,
        onlysetmax: adm.onlysetmax
    }

    this.SelectedAdmissions.push(newAdm);
    });

    this.SelectedPackage.resources.forEach(res => {
      var newRes: cResource = {
        groupname: uuidv4(),
        id: res.id,
        resourceId: res.resourceId,   
        name: res.name,
        useClasses: res.useClasses,
        exclusive: res.exclusive,
        concurrentUses: res.concurrentUses,
        maxConcurrent: res.maxConcurrent,
        disabled: res.disabled,
        venueId: res.venueId,
        colour: res.colour,
        orderindex: res.orderindex,
        length: res.length,
        classes:  res.classes,
        resources: res.resources,
        isresourcecontainer: res.isresourcecontainer,
        isconstructed: res.isconstructed,
        resourcegroupname: res.resourcegroupname,
        price: res.price,
        fixedprice: res.fixedprice,
        tags: res.tags,
        resourcegroup: res.resourcegroup,
        selectionmode: res.selectionmode,
        selectableresources: res.selectableresources,
        startwhen: res.startwhen,
        StartTimeOffset: res.StartTimeOffset,
        numofminutes: res.numofminutes,
        optional: res.optional,
        quantityselector: res.quantityselector,
        quantity: res.quantity,
        mode: res.mode,
        inline: res.inline,
        ignore: res.ignore,
        admissions: res.admissions,
        inProgress: res.inProgress,
        originatingid: res.originatingid,
        wasoptional: res.wasoptional,
        wasselected: res.wasselected
    }

    this.SelectedResources.push(newRes);
    });

    if(this.SelectedPackage.questionnaire != null)
    {
      this.SelectedPackage.questionnaire.bookingquestions.forEach(question => {
        question.answers = {
          bookingid: "",
          bookingquestionnaireid:  null,
          bookingquestionid: question.bookingquestionid,
          birthdayanswers: null,
          allergyanswer: {
            id: null,
            allergypresent: null,
            allergytext: ""
          },
          freetextanswer: "",
          numberanswer: null,
          dateanswer:  null,
          multichoicesingleanswer: {
            id: null,
            listitemtext: ""
          },
          multichoicemultianswer: []
        }
      });
    }

    if(this.PBWStyle == "DATEFIRST")
    {
      //Get day for selected date.
      let day = this.SelectedDate.getDay();

      this.packageExtras = [];

      if(this.SelectedPackage.extras != null)
      {
        switch (day)
        {
          case 0:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.sunday == true);
            break;
          case 1:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.monday == true);
            break;
          case 2:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.tuesday == true);
            break;
          case 3:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.wednesday == true);
            break;
          case 4:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.thursday == true);
            break;
          case 5:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.friday == true);
            break;
          case 6:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.saturday == true);
            break;
          default:
            this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.sunday == true);
            break;
        }
      }

      this.packageExtras.forEach(ext => {
        ext.qty = 0;
      });

      if(this.packageExtras.length > 0)
          this.PackageHasExtras = true;
    }

    this.SelectedResources.forEach(element => {
      if(element.mode == "COMPLEX")
      {
        if(element.selectionmode == "SYSTEMANY" || element.selectionmode == "SYSTEMLIST" || element.selectionmode == "") 
        {
          this.includesComplexResources = false;
        }
        else{
          this.includesComplexResources = true;
        }
        
      }
      else{
        this.includesComplexResources = false;
      }
      if(element.selectionmode != "SYSTEMANY" && element.selectionmode != "SYSTEMLIST")
      { 
        this.systemChoosesAllResources = false;
      }
      else
      {
        this.systemChoosesAllResources = true;
      }

      if(element.optional)
      {
        this.includesOptionalResources = true;
      }
    });

    if(!this.includesComplexResources || this.systemChoosesAllResources)
    {
      this.customerSelectedResources = this.SelectedPackage.resources;
    }

    if(this.includesComplexResources)
    {
      this.partyroomsVisible = true;
    }
    else
    {
      this.partyroomsVisible = false;
    }

    //this.sumAdmissions();

    if(this.PBWStyle == "DATEFIRST")
      {
        this.AbandonBooking();
        this.resetAfterPackage();
        this.sumAdmissions();
        this.setTab(Sections.Admissions);
      }
      else
      {
        this.setTab(Sections.Date);
      }
  }

  doneAdmissions()
  {
   let dropOut = false;
   let HasMustIncludes = false;
   let HasSelectedMustIncludes = false;

   this.SelectedAdmissions.forEach(element => {
       if(element.mustinclude)
       {
        HasMustIncludes = true;
       }
       if(element.mustinclude && element.noofpeople > 0)
       {
        HasSelectedMustIncludes = true;
       }
   });

   if(HasMustIncludes && !HasSelectedMustIncludes)
    {
      this.MustIncludePass = false;
      this.messageService.add({severity:'warn', summary:'Must include', detail:'You must include at least 1 of the admissions with an asterix'});
    }
    else
    {
      this.MustIncludePass = true;
    }

    if(this.totalAdmissionInRange)
    {
      if(this.MustIncludePass)
      {
        let AdmissionsList: string = "";

        this.SelectedAdmissions.forEach(adm => {
          AdmissionsList += adm.noofpeople + " x " +  adm.name + ", ";
        });

        if(AdmissionsList.length > 2)
        AdmissionsList = AdmissionsList.substring(0, AdmissionsList.length - 2);

       // this.trackEvent("P_Admissions_Chosen", AdmissionsList);

        this.AdmissionHeader = "You selected: " + AdmissionsList;

        if(this.SelectedPackage.questionnaire != null)
          {
            this.SelectedPackage.questionnaire.bookingquestions.forEach(question => {
      
              let bQuestions = [];
              if(question.questiontype == "BIRTHDAY" && !question.linkedtoparticipants)
              {
                this.birthdayitems = [];
                bQuestions.push({
                  index: 0, 
                  id: uuidv4(), 
                  name: "", 
                  age: 0, 
                  birthdate: new Date(),
                  gender: "NOT STATED",
                  interests: "",
                  showage: question.showage,
                  showdob: question.showdob,
                  showgender: question.showgender,
                  showinterests: question.showinterests,
                  dobday: question.dobday,
                  dobmonth: question.dobmonth,
                  dobyear: question.dobyear
                });
              }
              else if(question.questiontype == "BIRTHDAY" && question.linkedtoparticipants)
              {
                this.birthdayitems = [];
                let totalAllowed = 0;
  
                this.SelectedAdmissions.forEach(admission => {
                  if (admission.participant)
                  {
                    totalAllowed += admission.noofpeople;
                  }
                });
  
                let index = 0;
  
                for(var i = 0; i < totalAllowed; i++){
                  bQuestions.push({
                    index: index, 
                    id: uuidv4(), 
                    name: "", 
                    age: 0, 
                    birthdate: new Date(),
                    gender: "NOT STATED",
                    interests: "",
                    showage: question.showage,
                    showdob: question.showdob,
                    showgender: question.showgender,
                    showinterests: question.showinterests
                  });
  
                  index++;
                }
              }

              bQuestions.forEach(bQuestion => {
                this.birthdayitems.push(bQuestion);
              });

              question.answers = {
                bookingid: "",
                bookingquestionnaireid:  null,
                bookingquestionid: question.bookingquestionid,
                birthdayanswers: bQuestions,
                allergyanswer: {
                  id: null,
                  allergypresent: null,
                  allergytext: ""
                },
                freetextanswer: "",
                numberanswer: null,
                dateanswer:  null,
                multichoicesingleanswer: {
                  id: null,
                  listitemtext: ""
                },
                multichoicemultianswer: []
              }
            });
        }
  
        
        //DO Select Item

        let gtmAdmissions = [];

        this.packages.forEach(adm => {
          let gtmAdmission: any = {
            'item_id': adm.admissionid,
            'item_name': adm.name,
            'affiliation': "",
            'coupon': "",
            'discount': 0,
            'index': 0,
            'item_brand': "",
            'item_category': this.tag0,
            'item_category2': this.tag1,
            'item_category3': this.tag2,
            'item_category4': this.tag3,
            'item_category5': this.tag4,
            'item_list_id': this.packageGroupId,
            'item_list_name': this.packageGroupName,
            'item_variant': adm.name,
            'location_id': this.VenueID,
            'price': adm.price,
            'quantity': adm.noofpeople
          }
          
          gtmAdmissions.push(gtmAdmission);
        });
    
    
        let gtmdata = {
          'item_list_id': this.packageGroupId,
          'item_list_name': this.packageGroupName,

          'items': gtmAdmissions
        }
        
        this.trackEvent("select_item", gtmdata);
      

        if((this.includesComplexResources && !this.systemChoosesAllResources) || this.includesOptionalResources)
        {
          this.setTab(Sections.Partyroom);
          this.titleService.setTitle("Additional Resources - " + this.venueName);

        }
        else{
          this.setTab(Sections.Time);
          this.titleService.setTitle("Time - " + this.venueName);

          this.getAvailability();


        }
      }
    }
    else
    {
      if (this.totalAdmissions > this.SelectedPackage.max)
        this.messageService.add({severity:'warn', summary:'Admissions', detail:'There are too many admissions, please reduce the admissions to ' + this.SelectedPackage.max + ' or less.'});
      else
        this.messageService.add({severity:'warn', summary:'Admissions', detail:'There are too few admissions, please increase the admissions to ' + this.SelectedPackage.min + ' or more.'});
    }

    this.AbandonBooking();
  }

  doneTimes()
  {
    //Book slot
    this.setTab(40);
  }

  bookSlot(timeslot: AvailableTimes)
  {
    this.PBWStartTime = new Date();

    this.restartTimer(this.SelectedPackage.bookingtimeout);
    this.showTimer = true;
    
    this.titleService.setTitle("Customer - " + this.venueName);

    this.busyService.busy();
    this.TimeHeader = "You selected: " + new Date(timeslot.time).getUTCHours().toString().padStart(2, '0') + ":" + new Date(timeslot.time).getUTCMinutes().toString().padStart(2, '0');

    //this.trackEvent("P_Time_Chosen", new Date(timeslot.time).getUTCHours().toString().padStart(2, '0') + ":" + new Date(timeslot.time).getUTCMinutes().toString().padStart(2, '0'));

    var instanceID: any = "BMP1"
    var id:any = this.VenueID;
    var todaysDate: Date = new Date(timeslot.time);
    // var thisYear: any = todaysDate.getFullYear();
    // var thisMonth: any = ('00' + (todaysDate.getMonth() + 1)).slice(-2);
    // var thisDay: any = ('00' + todaysDate.getDate()).slice(-2);
    // var fullDate: any = thisYear + thisMonth + thisDay;
  

    var venueDate: any =  id + "_" + timeslot.time.toString().substring(0, 10).replace(/-/g, "");

    this.SelectedPackage.resources.forEach(adm => {
      adm.admissions = this.SelectedAdmissions;
   });

    let totalNoOfPeople: number = 0;

    this.NewBooking.venuedate = venueDate
    this.NewBooking.packageId = this.SelectedPackage.packageId;
    this.NewBooking.guests =  totalNoOfPeople;
    this.NewBooking.startTime = timeslot.time;
    this.NewBooking.PackageName = this.SelectedPackage.name;
    this.NewBooking.venueId = this.VenueID;
    this.NewBooking.payments = null;
    this.NewBooking.customer = null;
    this.NewBooking.resources = timeslot.resources; //this.SelectedPackage.resources;
    this.NewBooking.Disabled = false;
    this.NewBooking.outstanding = 0;
    this.NewBooking.name = "";
    this.NewBooking.inProgress = true;
    this.NewBooking.publicBooking = true;
    this.NewBooking.availabilityreq = this.AvailabilityReq;
    this.NewBooking.initialbooking = true;

    if(this.SelectedPackage.bookingtimeout == 0)
    {
      this.NewBooking.bookingtimeout = 300;
    }
    else
    {
      this.NewBooking.bookingtimeout = this.SelectedPackage.bookingtimeout;
    }

    this.NewBooking.resources.forEach(element => {
      element.inProgress = true;
    });

    this.http.post<booking_returndata>(bmxurl + "/savebooking", this.NewBooking).subscribe(response => {
      this.httpResponse = (response as booking_returndata);      

      if(this.httpResponse.succeeded)
      {
        this.ReturnedJWT = this.httpResponse.data.temptoken;
        this.BookingHasValue = true;
        this.Itinerary = this.httpResponse.data.bookingObj.itinerary;

        this.BookingResponse = this.httpResponse.data
        
        this.NewBooking = this.BookingResponse.bookingObj;

        let NumPeople: number = 0;
        this.NewBooking.resources[0].admissions.forEach(adm => {
          NumPeople += adm.noofpeople;
        });
  
        this.paymentAmount = this.BookingResponse.bookingObj.total;

        if(this.NewBooking.PublicBooking)
        {
          this.validPaymentAmount = this.NewBooking.totalpublicdeposit - this.NewBooking.totalpayments;
        }
        else{
          this.validPaymentAmount = this.NewBooking.totalvenuedeposit - this.NewBooking.totalpayments;
        }
  
        const formatter = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP'});
  
        this.makeABookingHeader = "Make a Booking - Sales Value: " + formatter.format(this.paymentAmount);
  
        //Do SignalR
        this.hubConnection = new HubConnectionBuilder()
          .withUrl(environment.servicestarget + "/PublicBooking/" + this.NewBooking.bookingId + "/" + this.ReturnedJWT
          , { }).build();

          this.hubConnection.start();

          this.hubConnection.on("publicBookingEvent", data => {
            if(this.NewBooking.totalpayments >= this.NewBooking.totalpublicdeposit)
            {
              this.donePayment();
              this.BookingComplete = true;
              this.PaymentInProgress = false;
              //this.DoGTM();
              this.busyService.idle();
              this.hubConnection.stop();
            }
            this.busyService.idle();
          });

        this.trackEvent("begin_checkout", "");

        if(!this.PackageHasExtras && this.SelectedPackage.questionnaire == null)
          {
            this.setTab(Sections.Customer);
          }
          else if(this.SelectedPackage.questionnaire != null)
          {
            this.titleService.setTitle("Booking Questions - " + this.venueName);
            this.setTab(Sections.BookingQuestions);
          }
          else
          {
            this.titleService.setTitle("Extras - " + this.venueName);
            this.setTab(Sections.Extras);
          }

        // if(!this.PackageHasExtras)
        // {
        //   this.setTab(Sections.Customer);
        // }
        // else
        // {
        //   this.titleService.setTitle("Extras - " + this.venueName);
        //   this.setTab(Sections.Extras);
        // }

        this.busyService.idle();
      }
      else
      {        
        //this.startTimesFiltered = [];
        this.getAvailability();
        //this.setTab(Sections.Admissions);
        this.messageService.add({severity:'error', summary:'Error', detail: this.httpResponse.messages});
        this.busyService.idle();
      }
    }, error => {
      console.log(error);
      this.busyService.idle();
      this.messageService.add({severity:'error', summary:'Error', detail: error.message});
    })
  }

  doneExtras()
  {

    this.allAnswersValid = true;

    this.packageExtras.forEach(element => {
      if((element.question != null && element.question != "") && element.qty > 0 && (element.answer == null || element.answer == "")) 
      {
        this.allAnswersValid = false;
      }

      if (element.qty == 0)
      {
        element.answer = "";
      }
    });

  if(this.allAnswersValid)
  {
      this.bookingExtras = "";

      this.ExtrasHeader = "You selected: ";

      let cost: number = 0;

      this.packageExtras.forEach(element => {
        if(element.qty == null)
        {
          element.qty = 0;
        }
        if(element.qty > 0)
        {
          this.ExtrasHeader += element.name + " x " + element.qty + ", ";
          this.bookingExtras += element.name + " x " + element.qty + ", ";
          cost += element.qty * element.price;
        }
      });

      if(this.ExtrasHeader.length > 14)
      {
        this.ExtrasHeader = this.ExtrasHeader.substring(0, this.ExtrasHeader.length - 2);
        //this.trackEvent("P_Extras_Chosen", this.ExtrasHeader);
      }
      else
      {
        this.ExtrasHeader = "You selected: No Extras";
      }

      this.NewBooking.extras = this.packageExtras;
      this.NewBooking.jwt = this.ReturnedJWT

      this.http.post<booking_returndata>(bmxurl + "/BookingExtra", this.NewBooking).subscribe(response => {
        this.httpResponse = (response as booking_returndata);      

        if(this.httpResponse.succeeded)
        {
          this.BookingResponse = this.httpResponse.data
          
          this.NewBooking = this.BookingResponse;

          this.paymentAmount = this.BookingResponse.totalpublicdeposit;

          this.totalValueOfBooking = this.NewBooking.total;
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail: this.httpResponse.messages});
          this.busyService.idle();
        }

        this.bookingExtras = this.bookingExtras.substring(0, this.bookingExtras.length - 2);

        const formatter = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP'});
    
        this.makeABookingHeader = "Make a Booking - Sales Value: " + formatter.format(this.paymentAmount);

        //this.SetupPayment();
        this.setTab(Sections.Customer);

      }, error => {
        console.log(error);
        this.messageService.add({severity:'error', summary:'Error', detail: error.message});
      })

  }
  }

  showterms()
  {
    //Get Terms

    this.http.get(bmxurl + "terms/" + this.VenueID).subscribe(response => {
      this.httpResponse = (response as terms_returndata)
      if (this.httpResponse.succeeded)
      {
        this.TermsAndConditions = (this.httpResponse.data as cTerms).terms;

        this.http.get(bmxurl + "terms").subscribe(response => {
          this.httpResponse = (response as terms_returndata)
          if (this.httpResponse.succeeded)
          {
            this.TermsAndConditions += "<BR>" +  (this.httpResponse.data as cTerms).terms;

            this.displayModalTerms = true;
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Terms & Conditions'});
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Terms & Conditions, error:' + error.messages[0]});
        });

      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Terms & Conditions'});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Terms & Conditions, error:' + error.messages[0]});
    });

  }

  doneCustomer()
  {
    this.showtandcerror = true;

    this.CustomerAllValid = true;

    if (this.tandcsValid == false) {
      this.CustomerAllValid = false;
      this.tandcsValid = false;
    }
    else {
      this.tandcsValid = true;
    }

   if(this.CustomerAllValid)
   {
      if(this.MinimalCustomerDetails)
      {
        if (this.lastName == "") {
          this.CustomerAllValid = false;
          this.lastNameValid = false;
        }
        else {
          this.lastNameValid = true;
        }

        if (this.email == "") {
          this.CustomerAllValid = false;
          this.EmailValid = false;
        }
        else {
          this.EmailValid = true;
        }

        if (this.mobile == "") {
          this.CustomerAllValid = false;
          this.MobileValid = false;
        }
        else {
          this.MobileValid = true;
        }
        //Check for valid email and mobile

      if (this.email != "") {
        if (!this.validateEmail(this.email)) {
          this.CustomerAllValid = false;
          this.EmailFormatValid = false;
        }
        else {
          this.EmailFormatValid = true;
        }
      }

      if(this.mobile != ""){
        if (!this.validateMobile(this.mobile)) {
          this.CustomerAllValid = false;
          this.MobileFormatValid = false;
        }
        else {
          this.MobileFormatValid = true;
        }
      }
      }
      else
      {
        if (this.firstName == "") {
          this.CustomerAllValid = false;
          this.firstNameValid = false;
        }
        else {
          this.firstNameValid = true;
        }

        if (this.lastName == "") {
          this.CustomerAllValid = false;
          this.lastNameValid = false;
        }
        else {
          this.lastNameValid = true;
        }

        if (this.address1 == "") {
          this.CustomerAllValid = false;
          this.Address1Valid = false;
        }
        else {
          this.Address1Valid = true;
        }

        if (this.town == "") {
          this.CustomerAllValid = false;
          this.TownValid = false;
        }
        else {
          this.TownValid = true;
        }

        if (this.postCode == "") {
          this.CustomerAllValid = false;
          this.PostCodeValid = false;
        }
        else {
          this.PostCodeValid = true;
        }

        if (this.email == "") {
          this.CustomerAllValid = false;
          this.EmailValid = false;
        }
        else {
          this.EmailValid = true;
        }

        if (this.mobile == "") {
          this.CustomerAllValid = false;
          this.MobileValid = false;
        }
        else {
          this.MobileValid = true;
        }
              //Check for valid email and mobile

      if (this.email != "") {
        if (!this.validateEmail(this.email)) {
          this.CustomerAllValid = false;
          this.EmailFormatValid = false;
        }
        else {
          this.EmailFormatValid = true;
        }
      }

      if(this.mobile != ""){
        if (!this.validateMobile(this.mobile)) {
          this.CustomerAllValid = false;
          this.MobileFormatValid = false;
        }
        else {
          this.MobileFormatValid = true;
        }
      }
      }
    }

    if(this.CustomerAllValid == true)
    {
      this.CustomerHeader = "Customer: " + this.firstName + "  " + this.lastName;

      //this.trackEvent("P_Customer_Chosen", this.lastName);

      let newCustomer: customer = {
        id: null,
        customerid: null,
        firstName: this.firstName,
        lastName: this.lastName,
        fullName: this.firstName + " " + this.lastName,
        address1: this.address1,
        address2: this.address2,
        townCity: this.town,
        postCode: this.postCode,
        email: this.email,
        mobile: this.mobile,
        dob: this.dob,
        venueId: this.VenueID,
        disabled: false,
        bookingid: this.NewBooking.bookingId,
        agreedtotandc: this.AgreeToTerms,
        agreedtoemailmarketing: this.emailMarketing,
        agreedtosmsmarketing: this.smsMarketing,
        jwt: this.ReturnedJWT
      }

      this.NewBooking.customer = newCustomer;
      //this.NewBooking.total = this.paymentAmount;

      let customerResponse: any;

      this.http.post(bmxurl + "/customer", newCustomer).subscribe(response => {
        customerResponse = (response as customer_returndata).data;
  
        if(this.BookingResponse.bookingObj == null)
        {
          this.NewBooking = this.BookingResponse;
        }
        else
        {
          this.NewBooking = this.BookingResponse.bookingObj;
        }

        this.paymentAmount = this.NewBooking.totalpublicdeposit;

        this.totalValueOfBooking = this.NewBooking.total;

        this.http.get<package_returndata>(bmxurl + "package/" + this.VenueID + "/" + this.NewBooking.packageId).subscribe(response => {
          let BookedPackage: any = (response as package_returndata).data;
           if(BookedPackage.publicDepositPolicy.type == "NoDeposit")
           {
            
            this.http.get<package_returndata>(bmxurl + "nodeposit/" + this.VenueID + "/" + this.NewBooking.bookingId + "/" + this.ReturnedJWT).subscribe(response => {
              if(response.succeeded)
              {
                let returned = response.data;
                this.DoSummary(returned);
                this.noDepositBooking = true;
                this.BookingComplete = true;
        
                this.BookingConfirmationSummary = "Booking Confirmation";
                this.BookingConfirmationText = "Your booking has been confirmed, please lookout for your confirmation email further information, we look forward to seeing you.";
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting creating booking'});
              }
            });

           }
           else
           {
            this.SetupPayment();
            this.titleService.setTitle("Payment - " + this.venueName);
            this.setTab(Sections.Payment);
           }
        });

      }, error => {
        console.log(error);
      })
    }
  }

  validateEmail(email){ 
    var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; 
    return re.test(email); 
  }

  validateMobile(mobile){ 
    var re = /^(\+44\s?|0)7\d{9}$/; 
    return re.test(mobile); 
  }

  donePayment()
  {
      this.error= "";
      this.paymentSuccess = "";

      if(this.AdmissionsList.length > 2)
        this.AdmissionsList = this.AdmissionsList.substring(0, this.AdmissionsList.length - 2);

        this.jbook = {
          bookingid: this.NewBooking.bookingId,
          jwt: this.ReturnedJWT
        }

        this.http.post(bmxurl + "PublicBooking", this.jbook).subscribe(response => {
          this.BookingResponse = (response as booking_returndata).data;
          //this.trackEvent("add_payment_info", this.BookingResponse.paid);
          
          this.DoSummary(this.BookingResponse);
          this.BookingComplete = true;
  
          this.BookingConfirmationSummary = "Booking Confirmation";
          this.BookingConfirmationText = "Your booking has been confirmed, please lookout for your confirmation email further information, we look forward to seeing you.";
  
          //Send confirmation
          // switch (this.EmailSMSConfirmation)
          // {
          //   case "None":
          //     //Do Nothing
          //     break;
          //   case "Email":
              //this.sendEmailConfirmation();
            //   break;
            // case "SMS":
            //   this.sendSMSConfirmation();
            //   break;
            // case "Both":
            //   this.sendSMSConfirmation();
            //   this.sendEmailConfirmation();
            //   break;
            // default:
            //   //Do Nothing
          //}

        }, error => {
          this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting updated booking'});
        })

  }  
  
  // sendSMSConfirmation(){
  //   this.http.get<customer>(bmxurl + "confirmation/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
  //    this.trackEvent("P_SMS_Confirmation_Sent", "");
  //   }, error => {
  //     this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the SMS'});
  //   })
  // }

  sendEmailConfirmation(){

    this.DoGTM();

    this.http.get<customer>(bmxurl + "confirmationemail/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
     // this.trackEvent("P_Email_Confirmation_Sent", "");
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the Email'});
    })
  }

  setTab(tabNumber: number)
  {
    if(tabNumber == Sections.Date)
    {
      if(this.PBWStyle=="DATEFIRST")
      {
        this.dateSelected = true;
        this.packageSelected = false;
        this.admissionsSelected = false;
        this.partyroomsSelected = false;
        this.timeSelected = false;
        this.customerSelected = false;
        this.paymentSelected = false;
        this.extraSelected = false;
        this.bookingQuestionsSelected = true;

        this.dateActive = false;
        this.packageActive = true;
        this.admissionsActive = true;
        this.partyroomsActive = true;
        this.timeActive = true;
        this.customerActive = true;
        this.paymentActive = true;
        this.extraActive = true;
        this.bookingQuestionsActive = true;
      }
      else
      {
        this.packageSelected = false;
        this.dateSelected = true;
        this.admissionsSelected = false;
        this.partyroomsSelected = false;
        this.timeSelected = false;
        this.customerSelected = false;
        this.paymentSelected = false;
        this.extraSelected = false;
        this.bookingQuestionsSelected = true;

        this.packageActive = false;
        this.dateActive = false;
        this.admissionsActive = true;
        this.partyroomsActive = true;
        this.timeActive = true;
        this.customerActive = true;
        this.paymentActive = true;
        this.extraActive = true;
        this.bookingQuestionsActive = true;
      }
    }
    if(tabNumber == Sections.Package) 
    {
      if(this.PBWStyle=="DATEFIRST")
      {
        this.dateSelected = false;
        this.packageSelected = true;
        this.admissionsSelected = false;
        this.partyroomsSelected = false;
        this.timeSelected = false;
        this.customerSelected = false;
        this.paymentSelected = false;
        this.extraSelected = false;
        this.bookingQuestionsSelected = false;

        this.dateActive = false;
        this.packageActive = false;
        this.admissionsActive = true;
        this.partyroomsActive = true;
        this.timeActive = true;
        this.customerActive = true;
        this.paymentActive = true;
        this.extraActive = true;
        this.bookingQuestionsActive = true;
      }
      else
      {
        this.packageSelected = true;
        this.dateSelected = false;
        this.admissionsSelected = false;
        this.partyroomsSelected = false;
        this.timeSelected = false;
        this.customerSelected = false;
        this.paymentSelected = false;
        this.extraSelected = false;
        this.bookingQuestionsSelected = false;

        this.dateActive = true;
        this.packageActive = false;
        this.admissionsActive = true;
        this.partyroomsActive = true;
        this.timeActive = true;
        this.customerActive = true;
        this.paymentActive = true;
        this.extraActive = true;
        this.bookingQuestionsActive = true;
      }
    }
    if(tabNumber == Sections.Admissions)
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = true;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.bookingQuestionsSelected = false;

      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = true;
      this.timeActive = true;
      this.customerActive = true;
      this.paymentActive = true;
      this.extraActive = true;
      this.bookingQuestionsActive = true;

      // this.router.navigate([], { fragment: 'topOfAdmissions' }).then(res => {
      //   const element = document.getElementById('topOfAdmissions');
      //   if (element != undefined) element.scrollIntoView({behavior: 'smooth'});
      // });
    }
    if(tabNumber == Sections.Partyroom)
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = true;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.bookingQuestionsSelected = false;

      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = true;
      this.customerActive = true;
      this.paymentActive = true;
      this.extraActive = true;
      this.bookingQuestionsActive = true;

      // this.router.navigate([], { fragment: 'topOfComplexResources' }).then(res => {
      //   const element = document.getElementById('topOfComplexResources');
      //   if (element != undefined) element.scrollIntoView({behavior: 'smooth'});
      // });
    }
    if(tabNumber == Sections.Time) 
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = true;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.bookingQuestionsSelected = false;

      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = true;
      this.paymentActive = true;
      this.extraActive = true;
      this.bookingQuestionsActive = true;

      // this.router.navigate([], { fragment: 'topOfTimes' }).then(res => {
      //   const element = document.getElementById('topOfTimes');
      //   if (element != undefined) element.scrollIntoView({behavior: 'smooth'});
      // });
    }
    if(tabNumber == Sections.BookingQuestions) 
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.bookingQuestionsSelected = true;


      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = false;
      this.paymentActive = true;
      this.extraActive = false;
      this.bookingQuestionsActive = false;

      // this.router.navigate([], { fragment: 'topOfQuestions' }).then(res => {
      //   const element = document.getElementById('topOfQuestions');
      //   if (element != undefined) element.scrollIntoView({behavior: 'smooth'});
      // });
      
    }
    if(tabNumber == Sections.Customer)
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = true;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.bookingQuestionsSelected = false;

      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = false;
      this.paymentActive = true;
      this.extraActive = false;
      this.bookingQuestionsActive = false;

      // this.router.navigate([], { fragment: 'topOfDetails' }).then(res => {
      //   const element = document.getElementById('topOfDetails');
      //   if (element != undefined) element.scrollIntoView({behavior: 'smooth'});
      // });
    }
    if(tabNumber == Sections.Extras)
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = true;
      this.bookingQuestionsSelected = false;

      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = true;
      this.extraActive = false;
      this.paymentActive = true;
      this.bookingQuestionsActive = false;

      // this.router.navigate([], { fragment: 'topOfExtras' }).then(res => {
      //   const element = document.getElementById('topOfExtras');
      //   if (element != undefined) element.scrollIntoView({behavior: 'smooth'});
      // });
    }
    if(tabNumber == Sections.Payment)
    {
      if(this.NewBooking.customer == null)
      {
        this.messageService.add({ severity: 'Customer', summary: 'Please enter a customer' });
        this.setTab(Sections.Customer);
        return;
      }

      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.extraSelected = false;
      this.paymentSelected = true;
      this.bookingQuestionsSelected = false;

      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = false;
      this.extraActive = false;
      this.paymentActive = false;
      this.bookingQuestionsActive = false;

      // this.router.navigate([], { fragment: 'topOfPayments' }).then(res => {
      //   const element = document.getElementById('topOfPayments');
      //   if (element != undefined) element.scrollIntoView({behavior: 'smooth'});
      // });
    }
  }

  // scrollTo(fragment): void {
  //   this.router.navigate([], { fragment: fragment }).then(res => {
  //     const element = document.getElementById(fragment);
  //     if (element != undefined) element.scrollIntoView({behavior: 'smooth'});
  //   });
  // }

  resetAfterDate(){
    this.setTab(Sections.Date);

    this.BookingComplete = false;

    this.PackageHeader = "Select a Package";
    this.AdmissionHeader = "Select your Admissions";
    this.TimeHeader = "Select a Time";
    this.CustomerHeader = "Enter your details";
    this.PaymentHeader = "Add a Payment";
    this.ExtrasHeader = "Select Extras";

    this.startTimes = null;
    this.startTimesFiltered = null;

    this.SelectedPackage = null;

    this.firstName = "";
    this.lastName = "";
    this.address1 = "";
    this.address2 = "";
    this.town = "";
    this.postCode = "";
    this.email = "";
    this.mobile = "";
    this.cardNumber = "";
    this.cvCode = "";
    this.expiryDate = "";
    this.showtandcerror = false;
    this.BookingHasValue = false;


    this.selectedPayment = null;
    this.packageExtras = [];
    this.selectedPackageExtras = [];
    this.NewBooking.extras= null;
    this.paymentAmount = 0;
    this.makeABookingHeader = "Make a Booking";
    this.NewBooking = {} as booking;
    this.customerSelectedResources = [];
    this.SelectedSeletableResourceBool = [];
    this.SelectedSeletableResource = [];
  
    this.checked = [];

    this.SelectedResources = [];

    this.tandcsValid = false;
    //this.packages = [];
  }

  resetAfterPackage(){
    this.setTab(Sections.Date);

    this.BookingComplete = false;

    this.AdmissionHeader = "Select your Admissions";
    this.TimeHeader = "Select a Time";
    this.CustomerHeader = "Enter your details";
    this.PaymentHeader = "Add a Payment";
    this.ExtrasHeader = "Select Extras";

    this.startTimes = null;
    this.startTimesFiltered = null;

    this.firstName = "";
    this.lastName = "";
    this.address1 = "";
    this.address2 = "";
    this.town = "";
    this.postCode = "";
    this.email = "";
    this.mobile = "";
    this.cardNumber = "";
    this.cvCode = "";
    this.expiryDate = "";
    this.showtandcerror = false;


    this.selectedPayment = null;
    //this.packageExtras = [];
    //this.selectedPackageExtras = [];
    this.NewBooking.extras= null;
    this.paymentAmount = 0;
    this.makeABookingHeader = "Make a Booking";
    this.NewBooking = {} as booking;
    //this.customerSelectedResources = [];
    //this.SelectedSeletableResourceBool = [];
    //this.SelectedSeletableResource = [];
  
    this.checked = [];

    //this.SelectedResources = [];

    this.tandcsValid = false;
    //this.packages = [];
  }

  restartBooking(){
    //this.router.navigateByUrl('/');
    window.location.href =this.router.url;
  }

  startAgain(){
    if (this.returnPage == "")
    {
      this.BookingTimeElasped = false;

      //this.setTab(Sections.Date);
      if(this.PBWStyle == "DATEFIRST")
        {
          this.dateSelected = true;
          this.setTab(Sections.Date);
        }
        else
        {
          this.packageSelected = true;
          this.setTab(Sections.Package);
        }

      this.BookingComplete = false;

      this.PackageHeader = "Select a Package";
      this.CalendarHeader = "Select a Date";
      this.AdmissionHeader = "Select your Admissions";
      this.TimeHeader = "Select a Time";
      this.CustomerHeader = "Create a Customer";
      this.PaymentHeader = "Add a Payment";
      this.ExtrasHeader = "Select Extras";

      this.startTimes = null;
      this.startTimesFiltered = null;

      this.SelectedPackage = null;
      this.SelectedDate = null;

      this.firstName = "";
      this.lastName = "";
      this.address1 = "";
      this.address2 = "";
      this.town = "";
      this.postCode = "";
      this.email = "";
      this.mobile = "";
      this.cardNumber = "";
      this.cvCode = "";
      this.expiryDate = "";

      this.showtandcerror = false;

      this.selectedPayment = null;
      this.packageExtras = [];
      this.selectedPackageExtras = [];
      this.NewBooking.extras= null;
      this.paymentAmount = 0;
      this.makeABookingHeader = "Make a Booking";
      this.NewBooking = {} as booking;
      this.customerSelectedResources = [];
      this.SelectedSeletableResourceBool = [];
      this.SelectedSeletableResource = [];
    
      this.checked = [];

      this.SelectedResources = [];

      this.tandcsValid = false;
    }
    else
    {
      this.returnPage = this.returnPage.toLowerCase().replace("http://","").replace("https://","")
      window.location.href = "https://" + this.returnPage;
    }


  }

  getAvailability(){

    let totalNoOfPeople: number = 0;

    this.AvailabilityReq.Admissions = [] as admission[];

    this.SelectedAdmissions.forEach(adm => {
    
      this.AvailabilityReq.Admissions.push(adm);
      totalNoOfPeople += adm.noofpeople;
      
   });

    this.AvailabilityReq.packageId = this.SelectedPackage.packageId;
    this.AvailabilityReq.NoOfPeople =  totalNoOfPeople;
    this.AvailabilityReq.venueid = this.VenueID;
    this.AvailabilityReq.StartTime =  this.SelectedDate.getFullYear() + "-" +  (this.SelectedDate.getMonth() + 1) + "-" +  this.SelectedDate.getDate() + "T00:00:00.000Z";
    this.AvailabilityReq.DiagnosticMode = false;
    this.AvailabilityReq.Resources = this.customerSelectedResources;
    this.AvailabilityReq.useintervalavailability = this.UseIntervalAvailability;

    this.http.post<AvailableTimes>(bmxurl + "/Availability",this. AvailabilityReq).subscribe(response => {
      this.startTimes =(response as AvailableTimes)
      let found: Boolean = false;

      this.startTimesFiltered = [];
      this.startTimes.forEach(element => {
        if(element.timeAvailable)
        {
          this.startTimesFiltered.forEach(innerelement => {
            if(innerelement.time == element.time)
              found = true;
          });

          if(!found)
          {
            this.startTimesFiltered.push(element);
          }
          found = false;
        }
      });

      //If there is only one time, book it.
      if(this.startTimesFiltered.length == 1)
      {
        this.timeActive = false;
        this.timeSelected = false;
        this.bookSlot(this.startTimesFiltered[0]);
      }

      if(this.autoChooseTime)
      {
        if(this.startTimesFiltered != null && this.startTimesFiltered.length > 0)
        {
          this.startTimesFiltered.forEach(element => {

            if(element.timeString == this.autoChooseTimeValue)
            {
              this.bookSlot(element);
              this.timeSelected = false;
            }
          });
        }
        
    }
      console.log(response);
    }, error => {
      console.log(error);
  })


  }

  VCContinue()
  {
    this.showVCWarning = false;
  }

  switchToToday(){
    this.SelectedDate = new Date();
    this.doneDate();
  }

  switchTomorrow(){
    let tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);

    this.SelectedDate = tomorrow;
    this.doneDate();
  }

  switchToSaturday()
  {
    let saturday = new Date();
    let overflow = 0;

    while(saturday.getDay() != 6 && overflow <= 7)
    {
      saturday.setDate(saturday.getDate() + 1);
      overflow ++;
    }

    this.SelectedDate = saturday;
    this.doneDate();
  }

  switchToSunday()
  {
    let sunday = new Date();
    let overflow = 0;

    while(sunday.getDay() != 0 && overflow <= 7)
    {
      sunday.setDate(sunday.getDate() + 1);
      overflow ++;
    }

    this.SelectedDate = sunday;
    this.doneDate();
  }

  toggleMore(inDiv)
  {
    let collapsedDiv = document.getElementById(inDiv + "collapse") as HTMLElement;
    let expandeddDiv = document.getElementById(inDiv + "expanded") as HTMLElement;
    let toggleButton = document.getElementById(inDiv + "toggleButton") as HTMLButtonElement;

    if(collapsedDiv.hidden == true)
    {
      collapsedDiv.hidden = false
      collapsedDiv.style.display = "false";
    }
    else
    {
      collapsedDiv.hidden = true
      collapsedDiv.style.display = "true";
    }

    if(expandeddDiv.hidden == true)
    {
      expandeddDiv.hidden = false
      expandeddDiv.style.display = "false";
    }
    else
    {
      expandeddDiv.hidden = true
      expandeddDiv.style.display = "true";
    }

    if(toggleButton.value == "Show More...")
    {
      toggleButton.value = "Show Less..."
      toggleButton.textContent = "Show Less..."
    }
    else
    {
      toggleButton.value = "Show More..."
      toggleButton.textContent = "Show More..."
    }
  }
}

enum Sections {
  Date = 0,
  Package = 1,
  Admissions = 2,
  Partyroom = 3,
  Time = 4,
  Customer = 5,
  Extras = 6,
  Notes = 7,
  Payment = 8,
  BookingQuestions = 9
}



