
  <head>
    <title>WannaBook-PBW</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no">
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="generator" content="Zoho Sites 2.0, https://zoho.com/sites" />
    <link rel="canonical" href="http://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/wannabook-pbw/" />
    <meta name="twitter:card" content="summary">
    <meta name="twitter:url" content="http://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/wannabook-pbw/">
    <meta name="twitter:title" content="WannaBook-PBW - WannaBook-PBW">
    <meta property="og:type" content="website">
    <meta property="og:url" content="http://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/wannabook-pbw/">
    <meta property="og:title" content="WannaBook-PBW - WannaBook-PBW">
    <link href="https://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/WannaBook-PBW/css/zsite-core.css" rel="preload" type="text/css" as="style">
    <link href="https://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/WannaBook-PBW/css/zsite-core.css" rel="stylesheet" type="text/css">
    <link rel="preconnect" href="//img.zohostatic.eu">
    <link rel="preconnect" href="//static.zohocdn.com">

    <link href="https://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/WannaBook-PBW/template/f28c43aad1aa4595b54584c6fc859e52/stylesheets/style.css" rel="stylesheet" type="text/css">
    <link href="https://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/WannaBook-PBW/template/f28c43aad1aa4595b54584c6fc859e52/stylesheets/dof-style.css" rel="preload" type="text/css" as="style">
    <link href="https://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/WannaBook-PBW/template/f28c43aad1aa4595b54584c6fc859e52/stylesheets/dof-style.css" rel="stylesheet" type="text/css">
    <link href="https://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/WannaBook-PBW/template/f28c43aad1aa4595b54584c6fc859e52/stylesheets/sub-style.css" rel="preload" type="text/css" as="style">
    <link href="https://wannabook.zoholandingpage.eu/zoho-marketing-automation-workspace/WannaBook-PBW/template/f28c43aad1aa4595b54584c6fc859e52/stylesheets/sub-style.css" rel="stylesheet" type="text/css">
    <link rel="preload" type="text/css" href="https://webfonts.zoho.eu/css?family=TeXGyreAdventor:700,400,700i,400i/Montserrat:900,800,700,600,500,300,200,100,400,200i,300i,900i,600i,700i,500i,800i,400i,100i/Roboto:500,700,900,100,300,400,100i,400i,500i,700i,900i/Arimo:700,700i,400i/Lora:400,700/Open Sans:300,600,700,800,400/Nunito Sans:200,300,600,700,800,900,400/Bebas:400/Lato:400/Raleway:900,800,700,600,300,500,200,100,400/Poppins:100,200,300,500,600,900,400/Leckerli One:400/Sarina:400/Pacifico:400&amp;display=swap" as="style">
    <link rel="stylesheet" type="text/css" href="https://webfonts.zoho.eu/css?family=TeXGyreAdventor:700,400,700i,400i/Montserrat:900,800,700,600,500,300,200,100,400,200i,300i,900i,600i,700i,500i,800i,400i,100i/Roboto:500,700,900,100,300,400,100i,400i,500i,700i,900i/Arimo:700,700i,400i/Lora:400,700/Open Sans:300,600,700,800,400/Nunito Sans:200,300,600,700,800,900,400/Bebas:400/Lato:400/Raleway:900,800,700,600,300,500,200,100,400/Poppins:100,200,300,500,600,900,400/Leckerli One:400/Sarina:400/Pacifico:400&amp;display=swap">
    <script>
      document.documentElement.removeAttribute('data-nojs');
    </script>
    <script>
      window.zs_content_format = "0";
      window.zs_resource_url = "/zoho-marketing-automation-workspace/WannaBook-PBW/";
      window.isDefaultLogo = "true";
      window.zs_site_resource_id = "126217000000004007";
    </script>
    <script>
      window.is_portal_site = "false";
    </script>
     <script>
      window.zs_data_center = "Europe";
    </script>
    <script>
      window.stand_alone_path = "";
    </script>
    <script>
      window.zs_rendering_mode = "live";
    </script>
    <script>
      window.is_social_share_enabled = "true";
    </script>
    <script>
      window.goal_js_key_name = "mhproject-customevent";
      window.lpageportal = "euw0gouq";
    </script>
    <script>
      window.zs_resource_id = "126217000000004029";
      window.zs_resource_type = "1";
      window.zs_site_resource_path = "/zoho-marketing-automation-workspace/WannaBook-PBW";
      window.zs_resource_full_path = "/zoho-marketing-automation-workspace/WannaBook-PBW/";
      window.zs_site_resource_id = "126217000000004007";
    </script>
  </head>
  <body>
    <div class="theme-content-area">
      <div class="theme-content-container">
        <div class="theme-content-area-inner">
          <div class="zpcontent-container page-container ">
            <div data-element-id="elm_6DmBgSDoxThuW-wJ-n3BpA" data-element-type="section" class="zpsection zpshape-divider-section zplight-section zplight-section-bg " style="background-color:rgb(255, 255, 255);background-image:unset;">
              <style type="text/css">
                [data-element-id="elm_6DmBgSDoxThuW-wJ-n3BpA"].zpsection {
                  border-style: none;
                  border-radius: 1px;
                  padding-block-end: 26px;
                }
              </style>
              <div class="zpcontainer-fluid">
                <div data-element-id="elm_INPyRMz6SYHstR5GKHKZuQ" data-element-type="row" class="zprow zpalign-items- zpjustify-content- ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_uIGOGhSwgkp7eG2QzT7Ttw" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-12 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css">
                      [data-element-id="elm_uIGOGhSwgkp7eG2QzT7Ttw"].zpelem-col {
                        border-radius: 1px;
                      }
                    </style>
                    <div data-element-id="elm_AgsuGjZSFiTGWK4ci4SmKw" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_AgsuGjZSFiTGWK4ci4SmKw"] div.zpspacer {
                          height: 8px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_AgsuGjZSFiTGWK4ci4SmKw"] div.zpspacer {
                            height: calc(8px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="8"></div>
                    </div>
                    <div data-element-id="elm_QiHosPKJj5EYklNwB5qvaA" data-element-type="image" class="zpelement zpelem-image ">
                      <style>
                        @media (min-width: 992px) {
                          [data-element-id="elm_QiHosPKJj5EYklNwB5qvaA"] .zpimage-container figure img {
                            width: 361px !important;
                            height: 62px !important;
                          }
                        }

                        [data-element-id="elm_QiHosPKJj5EYklNwB5qvaA"].zpelem-image {
                          border-radius: 1px;
                        }
                      </style>
                      <div data-caption-color="" data-size-tablet="" data-size-mobile="" data-align="center" data-tablet-image-separate="false" data-mobile-image-separate="false" class="zpimage-container zpimage-align-center zpimage-tablet-align-center zpimage-mobile-align-center zpimage-size-original zpimage-tablet-fallback-small zpimage-mobile-fallback-small hb-lightbox " data-lightbox-options="
                type:fullscreen,
                theme:dark">
                        <figure role="none" class="zpimage-data-ref"><span class="zpimage-anchor" role="link" tabindex="0" aria-label="Open Lightbox" style="cursor:pointer;">
                            <picture><img class="zpimage zpimage-style-none zpimage-space-none " src="https://wannabook.zoholandingpage.eu/WannaBook-Logo-Final-72dpi.png" size="original" data-lightbox="true" /></picture>
                          </span></figure>
                      </div>
                    </div>
                    <div data-element-id="elm_5MGixaQx7A_zwOjHwuwdvw" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_5MGixaQx7A_zwOjHwuwdvw"] div.zpspacer {
                          height: 69px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_5MGixaQx7A_zwOjHwuwdvw"] div.zpspacer {
                            height: calc(69px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="69"></div>
                    </div>
                    <div data-element-id="elm__k6idMqaUIKFOC9cv2CZHA" data-element-type="heading" class="zpelement zpelem-heading ">
                      <style>
                        [data-element-id="elm__k6idMqaUIKFOC9cv2CZHA"].zpelem-heading {
                          border-radius: 1px;
                        }
                      </style>
                      <h1 class="zpheading zpheading-style-none zpheading-align-center " data-editor="true">
                        <div style="color:inherit;">
                          <p><span style="font-weight:700;">Need a quick and easy solution to manage your bookings?</span></p>
                          <p><span style="font-weight:700;">WannaBook has been designed with ease of use in mind.</span></p>
                          <p></p>
                        </div>
                      </h1>
                    </div>
                    <div data-element-id="elm_VNpwpqw_wDp4eGXN8YwpZw" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_VNpwpqw_wDp4eGXN8YwpZw"] div.zpspacer {
                          height: 17px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_VNpwpqw_wDp4eGXN8YwpZw"] div.zpspacer {
                            height: calc(17px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="17"></div>
                    </div>
                    <div data-element-id="elm_J7P6ASoq4dSjb4Io4Dz0fA" data-element-type="text" class="zpelement zpelem-text ">
                      <style>
                        [data-element-id="elm_J7P6ASoq4dSjb4Io4Dz0fA"].zpelem-text {
                          border-style: none;
                          border-radius: 1px;
                          margin-block-start: 2px;
                        }
                      </style>
                      <div class="zptext zptext-align-center " data-editor="true">
                        <div style="color:inherit;">
                          <p><br></p>
                          <p><span style="font-size:18px;">Crafted with care and 25+ years of expertise, WannaBook simplifies booking for you and your customers.&nbsp;</span></p>
                          <p><span style="font-size:18px;">Let us be your trusted partner in success.</span></p>
                        </div>
                      </div>
                    </div>
                    <div data-element-id="elm_K_nycOcjConhLC6UMtsZMA" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_K_nycOcjConhLC6UMtsZMA"] div.zpspacer {
                          height: 43px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_K_nycOcjConhLC6UMtsZMA"] div.zpspacer {
                            height: calc(43px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="43"></div>
                    </div>
                    <div data-element-id="elm_gJA9mFEKiGKWaNlSloI2Pg" data-element-type="button" class="zpelement zpelem-button " data-make-conversion="true">
                      <style>
                        [data-element-id="elm_gJA9mFEKiGKWaNlSloI2Pg"].zpelem-button {
                          border-style: none;
                          border-radius: 1px;
                          margin-block-start: 1px;
                        }
                      </style>
                      <div class="zpbutton-container zpbutton-align-center">
                        <style type="text/css">
                          [data-element-id="elm_gJA9mFEKiGKWaNlSloI2Pg"] .zpbutton.zpbutton-type-primary {
                            border-style: solid;
                            border-color: #000000 !important;
                            border-width: 0px;
                            border-radius: 100px;
                          }
                        </style><a class="zpbutton-wrapper zpbutton zpbutton-type-primary zpbutton-size-lg zpbutton-style-none " href="https://forms.zohopublic.eu/wannabook/form/WannaBookmoreinformation/formperma/86xMVAb7izL_u8QjUawWwPCTEjH7Ol4HXD15nLezQGo" rel="nofollow noreferrer noopener" target="_blank"><span class="zpbutton-content">WANNA KEEP IN TOUCH - CLICK HERE</span></a>
                      </div>
                    </div>
                    <div data-element-id="elm_VDtOBJ9as7YKH9cIR7p-tw" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_VDtOBJ9as7YKH9cIR7p-tw"] div.zpspacer {
                          height: 64px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_VDtOBJ9as7YKH9cIR7p-tw"] div.zpspacer {
                            height: calc(64px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="64"></div>
                    </div>
                  </div>
                </div>
              </div>
              <style>
                [data-element-id="elm_6DmBgSDoxThuW-wJ-n3BpA"] .zpshape-divider-top {
                  color: rgba(255, 255, 255, 0.1) !important;
                }

                [data-element-id="elm_6DmBgSDoxThuW-wJ-n3BpA"] .zpshape-divider-top svg {
                  height: 300px !important;
                }
              </style>
              <div class="zpshape-divider zpshape-divider-top " data-shaper-top-flip="" data-shaper-top-hide-mobile=""><svg viewBox="0 0 1900 130" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                  <g fill-rule="nonzero">
                    <path d="M0 87.505c93.232 30.91 174.887 41.693 241.004 36.642 94.294-7.38 109.703-41.405 199.412-44.258 103.177-3.298 137.258 39.913 238.596 36.642 101.338-3.272 114.805-47.844 208.943-49.728 104.29-1.99 133.969 51.874 244.888 52.869 115.271 1.02 155.568-56.481 265.84-52.87 82.355 2.618 163.156 44.887 250.509 56.455C1690.775 128.753 1774.378 112.5 1900 74.5V0H0v87.505z" opacity=".2" />
                    <path d="M0 57.505c93.232 30.91 174.887 41.693 241.004 36.642 94.294-7.38 109.703-41.405 199.412-44.258 103.177-3.298 137.258 39.913 238.596 36.642 101.338-3.272 114.805-47.844 208.943-49.728 104.29-1.99 133.969 51.874 244.888 52.869 115.271 1.02 155.568-56.481 265.84-52.87 82.355 2.618 163.156 44.887 250.509 56.455 41.534 5.49 125.137-10.763 250.808-48.757V0H0v57.505z" />
                  </g>
                </svg></div>
            </div>
            <div data-element-id="elm_-N6XRbhpovDvAq5aaa5VpA" data-element-type="section" class="zpsection zplight-section zplight-section-bg ">
              <style type="text/css">
                [data-element-id="elm_-N6XRbhpovDvAq5aaa5VpA"].zpsection {
                  border-radius: 1px;
                }
              </style>
              <div class="zpcontainer">
                <div data-element-id="elm_lJ9X5eIJ82vZs9u8jHFipA" data-element-type="row" class="zprow zpalign-items- zpjustify-content- ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_6KSARpcaZFgQbjYd5tThgA" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-12 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_9C4fsFkgibMzE0ECvpQB3A" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_9C4fsFkgibMzE0ECvpQB3A"] div.zpspacer {
                          height: 17px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_9C4fsFkgibMzE0ECvpQB3A"] div.zpspacer {
                            height: calc(17px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="17"></div>
                    </div>
                  </div>
                </div>
                <div data-element-id="elm_MiQHn06eEEgxvCc7LDmg8A" data-element-type="row" class="zprow zpalign-items- zpjustify-content- ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_hSyX_ckKDqenXOuOYPb6FA" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-6 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_EFE-c7QAG0iRzKIwvXOheQ" data-element-type="iconHeadingText" class="zpelement zpelem-iconheadingtext ">
                      <style type="text/css">
                        [data-element-id="elm_EFE-c7QAG0iRzKIwvXOheQ"].zpelem-iconheadingtext {
                          border-style: none;
                          border-radius: 1px;
                        }
                      </style>
                      <div class="zpicon-container zpicon-align-center ">
                        <style></style><span class="zpicon zpicon-common zpicon-size-lg zpicon-style-none "><svg viewBox="0 0 448 512" height="448" width="512" aria-label="hidden" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"></path>
                          </svg></span>
                        <h4 class="zpicon-heading " data-editor="true"><span style="font-size:26px;font-weight:700;">Easy online booking</span></h4>
                        <div class="zpicon-text-container " data-editor="true">
                          <p>Your customers can make bookings online at any time. Whether bookings require a deposit, or full payment to confirm the booking, you can easily set the rule according to the package.&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-element-id="elm_Iywx2mNYGEasIWmKOv0JvA" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-6 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_l2JoGJfSYe13JcOjsq5Qfg" data-element-type="iconHeadingText" class="zpelement zpelem-iconheadingtext ">
                      <style type="text/css">
                        [data-element-id="elm_l2JoGJfSYe13JcOjsq5Qfg"].zpelem-iconheadingtext {
                          border-style: none;
                          border-radius: 1px;
                        }
                      </style>
                      <div class="zpicon-container zpicon-align-center ">
                        <style></style><span class="zpicon zpicon-common zpicon-size-lg zpicon-style-none "><svg viewBox="0 0 448 512" height="448" width="512" aria-label="hidden" xmlns="http://www.w3.org/2000/svg">
                            <path d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z"></path>
                          </svg></span>
                        <h4 class="zpicon-heading " data-editor="true"><span style="font-size:26px;font-weight:700;">Venue Management tool</span><br></h4>
                        <div class="zpicon-text-container " data-editor="true">
                          <p>Clear visibility of your bookings - whether they are today, tomorrow or in the future. Easily access settings and reports via the Venue Management log in.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-element-id="elm_vTH6JYFAZRh9bEmhWXUaiw" data-element-type="row" class="zprow zpalign-items- zpjustify-content- ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_ce23uGMqihM_0ntZ00liiQ" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-12 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_fTA1xRovcJXFX-l5CVWDFQ" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_fTA1xRovcJXFX-l5CVWDFQ"] div.zpspacer {
                          height: 39px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_fTA1xRovcJXFX-l5CVWDFQ"] div.zpspacer {
                            height: calc(39px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="39"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-element-id="elm_2EpOze3YmGMFOq8U0B3img" data-element-type="section" class="zpsection zpdark-section zpdark-section-bg zpbackground-size-cover zpbackground-position-center-center zpbackground-repeat-repeat zpbackground-attachment-fixed " style="background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(/images/premium_photo-1661768289109-c814be8d7c3d);">
              <style type="text/css">
                [data-element-id="elm_2EpOze3YmGMFOq8U0B3img"].zpsection {
                  border-radius: 1px;
                }
              </style>
              <div class="zpcontainer">
                <div data-element-id="elm_RzfZdAtu_iAnqROrTwMg_g" data-element-type="row" class="zprow zpalign-items- zpjustify-content- ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_G47w-ogLrEeAY1cxPoqyLg" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-12 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_jnA-klHGDPlVPWOAG0kgIA" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_jnA-klHGDPlVPWOAG0kgIA"] div.zpspacer {
                          height: 17px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_jnA-klHGDPlVPWOAG0kgIA"] div.zpspacer {
                            height: calc(17px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="17"></div>
                    </div>
                  </div>
                </div>
                <div data-element-id="elm_kE1gywJXoMJmGijNtq_UQA" data-element-type="row" class="zprow zpalign-items- zpjustify-content- ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_6_zsHulRXuNm12POrEQDRA" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-6 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_5kcjwawiquM4NvafhR8_Og" data-element-type="iconHeadingText" class="zpelement zpelem-iconheadingtext ">
                      <style type="text/css">
                        [data-element-id="elm_5kcjwawiquM4NvafhR8_Og"].zpelem-iconheadingtext {
                          border-radius: 1px;
                        }
                      </style>
                      <div class="zpicon-container zpicon-align-center ">
                        <style>
                          [data-element-id="elm_5kcjwawiquM4NvafhR8_Og"] .zpicon-common svg {
                            fill: #FFFFFF !important;
                          }

                          [data-element-id="elm_5kcjwawiquM4NvafhR8_Og"] .zpicon-common.zpicon-style-bgfill,
                          [data-element-id="elm_5kcjwawiquM4NvafhR8_Og"] .zpicon-common.zpicon-style-circle-fill,
                          [data-element-id="elm_5kcjwawiquM4NvafhR8_Og"] .zpicon-common.zpicon-style-roundcorner-fill {
                            background: #fe3152 !important;
                          }
                        </style><span class="zpicon zpicon-common zpicon-size-lg zpicon-style-circle-fill "><svg viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1792 1248V288q0-13-9.5-22.5T1760 256H160q-13 0-22.5 9.5T128 288v960q0 13 9.5 22.5t22.5 9.5h1600q13 0 22.5-9.5t9.5-22.5zm128-960v960q0 66-47 113t-113 47h-736v128h352q14 0 23 9t9 23v64q0 14-9 23t-23 9H544q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h352v-128H160q-66 0-113-47T0 1248V288q0-66 47-113t113-47h1600q66 0 113 47t47 113z"></path>
                          </svg></span>
                        <h4 class="zpicon-heading " data-editor="true">Arrange a demo</h4>
                        <div class="zpicon-text-container " data-editor="true">
                          <p><span style="font-size:24px;">Arrange a free no obligation demonstration to run through your requirements</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-element-id="elm_D5h8Ad-5PPJjhbeVDnAnbg" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-6 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_Q5xovyFeibDK98_aF1_Avw" data-element-type="iconHeadingText" class="zpelement zpelem-iconheadingtext ">
                      <style type="text/css">
                        [data-element-id="elm_Q5xovyFeibDK98_aF1_Avw"].zpelem-iconheadingtext {
                          border-radius: 1px;
                        }
                      </style>
                      <div class="zpicon-container zpicon-align-center ">
                        <style>
                          [data-element-id="elm_Q5xovyFeibDK98_aF1_Avw"] .zpicon-common svg {
                            fill: #FFFFFF !important;
                          }

                          [data-element-id="elm_Q5xovyFeibDK98_aF1_Avw"] .zpicon-common.zpicon-style-bgfill,
                          [data-element-id="elm_Q5xovyFeibDK98_aF1_Avw"] .zpicon-common.zpicon-style-circle-fill,
                          [data-element-id="elm_Q5xovyFeibDK98_aF1_Avw"] .zpicon-common.zpicon-style-roundcorner-fill {
                            background: #c700b3 !important;
                          }
                        </style><span class="zpicon zpicon-common zpicon-size-lg zpicon-style-circle-fill "><svg viewBox="0 0 512 513.5" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 80h320v256H197.5L122 396.5l-26 21V336H32V80zm32 32v192h64v46.5l54-43 4.5-3.5H320V112H64zm320 32h96v256h-64v81.5L314.5 400h-149l40-32h120l58.5 46.5V368h64V176h-64v-32z"></path>
                          </svg></span>
                        <h4 class="zpicon-heading " data-editor="true">Chat with us&nbsp;</h4>
                        <div class="zpicon-text-container " data-editor="true">
                          <p><span style="font-size:24px;">Have some questions.... chat with us before arranging a demonstration</span><br></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-element-id="elm_pHDrDCDlL6QDduzzOziE-w" data-element-type="row" class="zprow zpalign-items- zpjustify-content- ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_ZjiIpUWpln211-XuHTln6g" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-12 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_38jd-WH0l3vUGI2hiCZgIA" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_38jd-WH0l3vUGI2hiCZgIA"] div.zpspacer {
                          height: 39px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_38jd-WH0l3vUGI2hiCZgIA"] div.zpspacer {
                            height: calc(39px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="39"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-element-id="elm_WW8LGId6nKtVigRR2cbdww" data-element-type="section" class="zpsection zpshape-divider-section zpdark-section zpdark-section-bg ">
              <style type="text/css">
                [data-element-id="elm_WW8LGId6nKtVigRR2cbdww"].zpsection {
                  border-style: none;
                  border-radius: 1px;
                  padding-block-start: 1px;
                  padding-block-end: 1px;
                }
              </style>
              <div class="zpcontainer">
                <div data-element-id="elm_yM2_cECktEkTMygzBo2JrQ" data-element-type="row" class="zprow zpalign-items- zpjustify-content- ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_bKhLENbHjKp7Q9ketS39gw" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-12 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css">
                      [data-element-id="elm_bKhLENbHjKp7Q9ketS39gw"].zpelem-col {
                        border-radius: 1px;
                      }
                    </style>
                    <div data-element-id="elm_05XtCknkRJL4CG5P9K2fzQ" data-element-type="heading" class="zpelement zpelem-heading ">
                      <style>
                        [data-element-id="elm_05XtCknkRJL4CG5P9K2fzQ"].zpelem-heading {
                          border-style: none;
                          border-radius: 1px;
                        }
                      </style>
                      <h2 class="zpheading zpheading-style-none zpheading-align-center " data-editor="true"><span style="font-size:40px;"><b>Features include:</b></span><br></h2>
                    </div>
                    <div data-element-id="elm_2GFGRzu2YGzC0AOmLd5K0Q" data-element-type="spacer" class="zpelement zpelem-spacer ">
                      <style>
                        div[data-element-id="elm_2GFGRzu2YGzC0AOmLd5K0Q"] div.zpspacer {
                          height: 17px;
                        }

                        @media (max-width: 768px) {
                          div[data-element-id="elm_2GFGRzu2YGzC0AOmLd5K0Q"] div.zpspacer {
                            height: calc(17px / 3);
                          }
                        }
                      </style>
                      <div class="zpspacer " data-height="17"></div>
                    </div>
                  </div>
                </div>
              </div>
              <style>
                [data-element-id="elm_WW8LGId6nKtVigRR2cbdww"] .zpshape-divider-bottom {
                  color: rgba(0, 0, 0, 0.1) !important;
                }

                [data-element-id="elm_WW8LGId6nKtVigRR2cbdww"] .zpshape-divider-bottom svg {
                  height: 255px !important;
                }
              </style>
              <div class="zpshape-divider zpshape-divider-bottom " data-shaper-bottom-flip="" data-shaper-bottom-hide-mobile=""><svg viewBox="0 0 1900 255" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
                  <g fill-rule="nonzero">
                    <path d="M96.016 253.468c48.962-11.242 89.2-4.236 138.952.003l370.368.25c54.098.263 86.253.186 96.465-.232 5.318-.218 8.457.393 9.728-.032 251.24-84.359 271.272-216.453 555.648-146.614 310.668 76.296 309.263-127.388 624.183-99.836 2.914.254 5.77.647 8.569 1.177.81.154.792 81.937-.056 245.35-1203.828.243-1805.113.22-1803.857-.066z" opacity=".3" />
                    <path d="M1899.757 254.716L.027 254.482v-5.492c4.076-3.483 12.153-6.702 24.232-9.657 325.048-79.535 401.147 35.427 614.935 7.433 213.788-27.995 315.273-195.467 623.457-89.979 308.185 105.488 313.58-175.972 628.57-148.414 4.1.36 6.936.933 8.505 1.721l.03 244.622z" />
                    <path d="M308.03 254.766c106.437-72.59 200.452-113.983 282.045-124.18 227.966-28.493 400.797 182.215 672.618 29.416 271.821-152.8 309.567-130.037 622.009-73.272 3.8.69 8.912 2.246 15.333 4.665l.029 163.65-1592.034-.28z" opacity=".2" />
                  </g>
                </svg></div>
            </div>
            <div data-element-id="elm_9UkICjb1xl7foj1pFSByMQ" data-element-type="section" class="zpsection zpdefault-section zpdefault-section-bg ">
              <style type="text/css"></style>
              <div class="zpcontainer">
                <div data-element-id="elm_HN4Xy900y2mfr7y81WcD1Q" data-element-type="row" class="zprow zpalign-items-flex-start zpjustify-content-flex-start ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_3TzEf5WWVMrnnLp0tFnBug" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-3 zpcol-sm-6 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_wig9epJKwzlGJeQabBgnfw" data-element-type="iconHeadingText" class="zpelement zpelem-iconheadingtext ">
                      <style type="text/css"></style>
                      <div class="zpicon-container zpicon-align-center ">
                        <style></style><span class="zpicon zpicon-common zpicon-size-md zpicon-style-none "><svg viewBox="0 0 448 512" height="448" width="512" aria-label="hidden" xmlns="http://www.w3.org/2000/svg">
                            <path d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
                          </svg></span>
                        <h3 class="zpicon-heading " data-editor="true">Online bookings and payments&nbsp;</h3>
                        <div class="zpicon-text-container " data-editor="true"></div>
                      </div>
                    </div>
                  </div>
                  <div data-element-id="elm_5pAh4MSd7bizkwCHRQPsMg" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-3 zpcol-sm-6 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_uqTmZqRO4RY88gzjQFo6-w" data-element-type="iconHeadingText" class="zpelement zpelem-iconheadingtext ">
                      <style type="text/css"></style>
                      <div class="zpicon-container zpicon-align-center ">
                        <style></style><span class="zpicon zpicon-common zpicon-size-md zpicon-style-none "><svg viewBox="0 0 320 512" height="320" width="512" aria-label="hidden" xmlns="http://www.w3.org/2000/svg">
                            <path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"></path>
                          </svg></span>
                        <h3 class="zpicon-heading " data-editor="true">Email and SMS Confirmations</h3>
                        <div class="zpicon-text-container " data-editor="true">
                          <p><br></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-element-id="elm_MyoHP5Qvp7lmnHtWsSa9VA" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-3 zpcol-sm-6 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_Nrt0utmbbnHpAXQuenZA8g" data-element-type="iconHeadingText" class="zpelement zpelem-iconheadingtext ">
                      <style type="text/css"></style>
                      <div class="zpicon-container zpicon-align-center ">
                        <style></style><span class="zpicon zpicon-common zpicon-size-md zpicon-style-none "><svg viewBox="0 0 512 512" height="512" width="512" aria-label="hidden" xmlns="http://www.w3.org/2000/svg">
                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"></path>
                          </svg></span>
                        <h3 class="zpicon-heading " data-editor="true">Easy Check-in on arrival</h3>
                        <div class="zpicon-text-container " data-editor="true">
                          <p><br></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-element-id="elm_LAsu9jhiWTXSGZkN2Tmq2A" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-3 zpcol-sm-6 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_4I00ZmToxnJSosURpRZiZA" data-element-type="iconHeadingText" class="zpelement zpelem-iconheadingtext ">
                      <style type="text/css"></style>
                      <div class="zpicon-container zpicon-align-center ">
                        <style></style><span class="zpicon zpicon-common zpicon-size-md zpicon-style-none "><svg viewBox="0 0 576 512" height="576" width="512" aria-label="hidden" xmlns="http://www.w3.org/2000/svg">
                            <path d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"></path>
                          </svg></span>
                        <h3 class="zpicon-heading " data-editor="true">Special Event ticket management</h3>
                        <div class="zpicon-text-container " data-editor="true">
                          <p><br></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-element-id="elm_7bkvp2NQEEkIXq5IKNkA_g" data-element-type="row" class="zprow zpalign-items-flex-start zpjustify-content-flex-start zpdefault-section zpdefault-section-bg ">
                  <style type="text/css"></style>
                  <div data-element-id="elm__kPq33ZUjl8vHYtZjmwUQw" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-12 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_37fWR3LV0kqYo5NvfRaiXA" data-element-type="button" class="zpelement zpelem-button " data-make-conversion="true">
                      <style></style>
                      <div class="zpbutton-container zpbutton-align-center">
                        <style type="text/css"></style><a class="zpbutton-wrapper zpbutton zpbutton-type-primary zpbutton-size-md zpbutton-style-oval " href="https://forms.zohopublic.eu/wannabook/form/WannaBookmoreinformation/formperma/86xMVAb7izL_u8QjUawWwPCTEjH7Ol4HXD15nLezQGo" rel="nofollow noreferrer noopener"  target="_blank"><span class="zpbutton-content">WANNA KEEP IN TOUCH - CLICK HERE</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-element-id="elm_3y59MBYqdpj7tU7iCqkAHQ" data-element-type="section" class="zpsection zpdefault-section zpdefault-section-bg ">
              <style type="text/css"></style>
              <div class="zpcontainer">
                <div data-element-id="elm_2-KVFYCSmdHsCrQZ0A5GxA" data-element-type="row" class="zprow zpalign-items-flex-start zpjustify-content-flex-start zpdefault-section zpdefault-section-bg ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_HwjbFL9C13FN4GcBiR1vOg" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-12 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                    <div data-element-id="elm_YgQT0ukCLxDD0jom17dpFA" data-element-type="iconHeadingText" class="zpelement zpelem-iconheadingtext ">
                      <style type="text/css">
                        [data-element-id="elm_YgQT0ukCLxDD0jom17dpFA"].zpelem-iconheadingtext {
                          margin-block-start: -54px;
                        }
                      </style>
                      <div class="zpicon-container zpicon-align-center ">
                        <style></style><span class="zpicon zpicon-common zpicon-size-md zpicon-style-none "><svg width="896" height="896" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1472 992v480q0 26-19 45t-45 19h-384v-384h-256v384h-384q-26 0-45-19t-19-45v-480q0-1 .5-3t.5-3l575-474 575 474q1 2 1 6zm223-69l-62 74q-8 9-21 11h-3q-13 0-21-7l-692-577-692 577q-12 8-24 7-13-2-21-11l-62-74q-8-10-7-23.5t11-21.5l719-599q32-26 76-26t76 26l244 204v-195q0-14 9-23t23-9h192q14 0 23 9t9 23v408l219 182q10 8 11 21.5t-7 23.5z" />
                          </svg></span>
                        <h4 class="zpicon-heading " data-editor="true">Contact Us</h4>
                        <div class="zpicon-text-container " data-editor="true">
                          <p>Email: <a href="mailto:sales@wannabook.online?subject=WannaBook%20enquiry&amp;bcc=support%40smart-entertainment.co.uk" title="sales@wannabook.online" rel="nofollow noreferrer noopener">sales&#64;wannabook.online</a></p>
                          <p>Tel: <a href="tel:0333%204440944" title="0333 4440944" rel="">0333 4440944</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-element-id="elm_bqbSIbtdY2U0DLEQ9zs5OQ" data-element-type="section" class="zpsection zpdefault-section zpdefault-section-bg ">
              <style type="text/css"></style>
              <div class="zpcontainer">
                <div data-element-id="elm_aVy_UWNYkSUiKqlYMiDhqg" data-element-type="row" class="zprow zpalign-items-flex-start zpjustify-content-flex-start zpdefault-section zpdefault-section-bg ">
                  <style type="text/css"></style>
                  <div data-element-id="elm_VZFdfKTCz4h6zflXN49I3w" data-element-type="column" class="zpelem-col zpcol-12 zpcol-md-12 zpcol-sm-12 zpalign-self- zpdefault-section zpdefault-section-bg ">
                    <style type="text/css"></style>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
